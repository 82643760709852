import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

i18n
    .use(initReactI18next)
    .init({
        fallbackLng: 'es',
        interpolation: {
            escapeValue: false
        },
        resources: {
            en: {
                translation: {
                    asignamentAppointment: 'Make appointment',
                    loadingSpecialist: 'Loading top specialists',
                    Warning: 'Warning',
                    Accept: 'Accept',
                    cancel: 'Cancel',
                    attention: 'Attention',
                    appointmentNotAssist: 'The appointment has been updated to status Did not attend',
                    appointmentConfirm: 'The appointment has been updated to confirmed status',
                    dataSentMail: 'Your data has been sent to the email',
                    userPwFail: 'Incorrect username and/or password.',
                    pwNotRecovery: `Password recovery could not be performed.`,
                    login: `Log in`,
                    user: `User`,
                    password: `Password`,
                    forgotPassword: `Have you forgotten your password?`,
                    back: `Back`,
                    recoveryPassword: `Recover password`,
                    messageLogin: `Enter the email you are with registered on the platform, the steps will be sent to this that must follow.`,
                    email: `email`,
                    send: `Send`,
                    createAccount: "Create account",
                    joinCommunity: "Join the inmed community and get access to",
                    scheduleAppointment: "Schedule quickly and easily.",
                    payAppointment: "Pay for your appointment virtually.",
                    accessWaitingList: "Access to waiting list. (coming soon)",
                    rateExperience: "You can rate your experience with specialists. (coming soon)",
                    signUp: "Register",
                    specialist: `Specialist`,
                    secretary: `Secretary`,
                    patient: `Paciente`,
                    joinInmed: "Do you want to join Inmed?",
                    joinInmedText: "Be part of the most reliable community of specialists, make yourself known and manage your schedule in a more organized way, with online payments and many more tools, all in one platform and with the best human talent. Leave us your information and we will provide you with all the details.",
                    join: "Join",
                    satisfactionSurvey: "Satisfaction Survey",
                    rateService: "How would you rate our service?",
                    satisfiedQualityProduct: "Are you satisfied with the quality of the product?",
                    recommendProductService: "Would you recommend our product/service to others?",
                    previous: "Previous",
                    next: "Next",
                    submit: "Submit",
                    availableSchedule: "Available Schedule",
                    from: "From",
                    to: "To",
                    procedure: "Procedure",
                    city: "City",
                    search: "Search",
                    map: "Map",
                    appointment: "Appointment",
                    bookAnAppointment: "Book an Appointment",
                    shareProfile: "Share Profile",
                    share: `Share`,
                    favorites: "Favorites",
                    addFavorites: "Add favorites",
                    aboutMe: "About me",
                    patientReviews: "Patient Reviews",
                    procedures: "Procedures",
                    studies: "Studies",
                    awardsRecognitions: "Associations",
                    contact: "Contact",
                    address: "Address",
                    phone: "Phone",
                    reviewaEmpty: "No reviews have been made yet",
                    record: "Rating",
                    specialistInfoError: `Specialist information couldn't be loaded. Please try again later.`,
                    consultingFavoriteSpecialist: `Consulting your favorite specialist....`,
                    searchValue: `Search Value`,
                    selectField: `Select Field`,
                    patientInfo: `Patient Information`,
                    documentType: `"Document Type`,
                    nationalID: `National ID`,
                    PasaportID: `Pasaporte`,
                    foreignID: `Foreign ID`,
                    civilRegistry: `Civil Registry`,
                    documentNumber: `Document Number`,
                    firstName: `First Name`,
                    lastName: `Last Name`,
                    selectPaymentMethod: `Select a payment method`,
                    securePayment: `Pay securely with Credit or Debit Card or Internet Banking through the Secure Servers of PayU Latam.`,
                    buyerInfo: `Buyer Information`,
                    buyerSameAsPatient: `Buyer information is the same as patient information.`,
                    fullName: `Full Name`,
                    reservationUnderReview: `Your reservation will be under review, the specialist will contact you to confirm your appointment.`,
                    clickOn: `By clicking on`,
                    privacyPolicy: `you will be accepting our`,
                    termsConditions: `Privacy Policy`,
                    pay: `Pay`,
                    book: `Book`,
                    noPaymentMethod: `Sorry, the specialist in question does not have payment methods enabled at this time. Please try again later or select another specialist to schedule your appointment.`,
                    payAmount: `Amount to pay`,
                    time: `Time`,
                    date: `Date`,
                    appointmentType: `Appointment Type`,
                    generalConsultation: `General Consultation (Assessment)`,
                    reservationDetails: `Reservation Details`,
                    reservationConfirmation: `Reservation Confirmation`,
                    and: `and`,
                    termsAndConditions: `Terms and Conditions`,
                    transactionSummary: `Transaction Summary`,
                    transactionStatus: `Transaction Status`,
                    transactionID: `Transaction ID`,
                    transactionReference: `Transaction Reference`,
                    totalValue: `Total Value`,
                    currency: `Currency`,
                    pendingTransactionMessage: `Your transaction is pending confirmation from PayU, to validate the status click on 'Check status' or validate the status in my appointments by clicking`,
                    pendingTransactionMessageNotLogin: `Your transaction is pending confirmation from PayU. To validate the status, click on 'Check status' or validate the status in 'My appointments' by logging in.`,
                    here: `Here`,
                    checkStatus: `Check status`,
                    backToHome: `Back to Home`,
                    signatureValidationFailed: `The digital signature of the transaction could not be validated.`,
                    pendingAppointmentMessage: `Your appointment is pending confirmation by the specialist. Validate the status in 'My appointments' by clicking here.`,
                    pendingAppointmentMessageNotLogin: `Your appointment is pending confirmation from the specialist. Validate the status in 'My appointments' by logging in`,
                    patientData: `Patient's Information`,
                    specialistData: `Specialist's Information`,
                    appointmentData: `Appointment Details`,
                    confirmationMessage: `The specialist will soon contact you to confirm.`,
                    appointmentPendingValidationMessage: `Your appointment is pending validation!`,
                    bookingInfo: `Booking Information`,
                    paymentReference: "Payment Reference",
                    paymentType: "Payment Type",
                    amount: "Amount",
                    bookingStatus: "Booking Status",
                    bookingDate: "Booking Date",
                    paymentStatus: "Payment Status",
                    calendarLoadError: "We couldn't load the calendar...",
                    previousMonth: "Previous month",
                    nextMonth: "Next month",
                    bookAppointment: "Book Appointment",
                    loginToContinuePayment: "Do you want to log in to continue with the payment?",
                    continueWithoutSession: "Continue without session",
                    fetchingProcedureInformation: "Fetching procedure information...",
                    noInformation: "No Information",
                    images: "Images",
                    videos: "Videos",
                    knowMoreAboutOurSpecialists: "Know more about our specialists",
                    viewProfile: "View Profile",
                    sortBy: "Sort by name",
                    seeMore: "See more",
                    changePassword: "Cambiar contraseña",
                    confirmNewPassword: "Confirmar contraseña nueva",
                    newPassword: "Contraseña nueva",
                    currentPassword: "Contraseña actual",
                    passwordChanged: "Se ha cambiado la contraseña.",
                    newPasswordNotMatch: "La contraseña nueva no coincide.",
                    confirmNewPasswordRequired: "Ingrese la confirmación de la contraseña nueva.",
                    currentPasswordRequired: "Ingrese la contraseña actual.",
                    register: "Register",
                    confirmPassword: "Confirm Password",
                    loginInfo: "Login Information",
                    phoneMobile: "Phone/Mobile",
                    gender: "Gender",
                    identification: "Identification",
                    select: "Select",
                    personalInfo: "Personal Information",
                    patientRegistration: "Patient Registration",
                    enterPassword: "Enter a password.",
                    passwordNotMatch: "Passwords do not match.",
                    invalidEmail: "The entered email is not valid.",
                    requiredFields: "All fields are mandatory.",
                    registrationSuccess: "Registration successful. You can now log in and enjoy the benefits of having an Inmed account.",
                    male: "Male",
                    female: "Female",
                    bestSpecialists: "Find the best Plastic Surgery specialists in the city of Medellín",
                    allSpecialists: "Discover all our specialists",
                    whatProcedures: "What are body and facial procedures?",
                    plasticSurgeryInfo: "If you are looking to have liposuction, breast augmentation or reduction, or any facial aesthetic procedure, keep in mind that this should only be performed by a Plastic Surgeon, and at Inmed you will find the best certified surgeons in the country. Contact us and we will help you in your process of change and renewal. We await you.",
                    moreProcedures: "Learn about more procedures",
                    message: "Message",
                    notValid: "is not valid",
                    emailEntered: "The entered email",
                    messageNotSent: "The message could not be sent, please try again later.",
                    thankYou: "Thank you for contacting us.",
                    aboutUs: "About Us",
                    aboutUsContent: "We are a group that connects plastic surgery patients with the best specialists in the country. Doctors who meet all medical criteria to offer the highest safety to all patients. Inmed together with the Colombian Society of Plastic Surgery (SCCP), the health cluster, and the authorized and accredited clinics for plastic, aesthetic, and reconstructive procedures will provide the best support and confidence to all patients, both local and international.",
                    vision: "Vision",
                    visionContent: "Inmed will be the world leader platform in the supply and access to medical services where we will only work with the medical associations of each country to offer the greatest trust and quality to our patients.",
                    mission: "Mission",
                    missionContent: "To offer the best specialists in each area of health through technological platforms that allow users the best experience in accessing the different treatments offered by the world of medicine.",
                    logout: "Logout",
                    myAccount: "My Account",
                    contactUs: "Contact Us",
                    specialists: "Specialists",
                    home: "Home",
                    logoutError: "Could not log out, please try again later.",
                    ourSocialMedia: "Our Social Media",
                    selectSpecialist: "Select Specialist",
                    website: "Website",
                    settings: "Settings",
                    myAppointments: "My Appointments",
                    history: "History",
                    patients: "Patients",
                    agenda: "Agenda"
                }
            },
            es: {
                translation: {
                    asignamentAppointment: 'Asignar cita',
                    loadingSpecialist: 'Cargando top especialistas',
                    Warning: 'Advertencia',
                    Accept: 'Aceptar',
                    cancel: 'Cancelar',
                    attention: 'Atención',
                    appointmentNotAssist: 'La cita ha sido actualizada a estado No asistio',
                    appointmentConfirm: 'La cita ha sido actualizada a estado confirmada',
                    dataSentMail: 'Sus datos se han enviado al correo',
                    userPwFail: 'Usuario y/o contraseña incorrectos.',
                    pwNotRecovery: `No se pudo realizar la recuperación de la contraseña.`,
                    login: `Iniciar sesión`,
                    user: `Usuario`,
                    password: `Contraseña`,
                    forgotPassword: `¿Has olvidado tu contraseña?`,
                    back: `Regresar`,
                    recoveryPassword: `Recuperar contraseña`,
                    messageLogin: `Ingrese el correo electronico con el que se encuentra registrado en la plataforma, a este se enviarán los pasos que debe seguir.`,
                    email: `Correo electronico`,
                    send: `Enviar`,
                    createAccount: `Crear cuenta`,
                    joinCommunity: `Se parte de la comunidad inmed y ten acceso a`,
                    scheduleAppointment: `Agendar de manera ágil y fácil.`,
                    payAppointment: `Paga la cita de manera virtual.`,
                    accessWaitingList: `Acceso a lista de espera. (próximamente)`,
                    rateExperience: `Puedes calificar tu experiencia con los especialistas. (próximamente)`,
                    signUp: `Regístrate`,
                    specialist: `Especialista`,
                    secretary: `Secretaria`,
                    patient: `Paciente`,
                    joinInmed: `¿Quieres unirte a Inmed?`,
                    joinInmedText: `Forma parte de la comunidad de especialistas más confiables, dáte a conocer y maneja tu agenda de forma más ordenada, con pagos en línea y muchas más herramientas, todo en una misma plataforma y con el mejor talento humano. Déjanos tus datos y te brindaremos toda la información.`,
                    join: `Unirme`,
                    availableSchedule: "Agenda disponible",
                    from: "Desde",
                    to: "Hasta",
                    procedure: "Procedimiento",
                    city: "Ciudad",
                    search: "Buscar",
                    map: "Mapa",
                    appointment: `Cita`,
                    bookAnAppointment: `Solicitar Cita`,
                    shareProfile: `Compartir perfil`,
                    share: `Compartir`,
                    favorites: `Favoritos`,
                    addFavorites: `Añadir a favoritos`,
                    aboutMe: `Acerca de mi`,
                    patientReviews: `Reseñas de pacientes`,
                    procedures: `Procedimientos`,
                    studies: `Estudios`,
                    awardsRecognitions: `Agremiaciones`,
                    contact: `Contacto`,
                    address: "Dirección",
                    phone: "Teléfonos",
                    reviewaEmpty: "Aun no se han realizado reseñas",
                    record: "Calificación",
                    specialistInfoError: `No se pudo cargar la información del especialista. Inténtalo más tarde.`,
                    consultingFavoriteSpecialist: `Consultando tu especialista favorito....`,
                    searchValue: `Valor de Búsqueda`,
                    selectField: `Seleccionar Campo`,
                    patientInfo: `Información del paciente`,
                    documentType: `Tipo de documento`,
                    nationalID: `Cédula de Ciudadanía`,
                    PasaportID: `Pasaporte`,
                    foreignID: `Cédula de Extranjería`,
                    civilRegistry: `Registro Civil`,
                    documentNumber: `Número de documento`,
                    firstName: `Nombre`,
                    lastName: `Apellido`,
                    selectPaymentMethod: `Selecciona un tipo de pago`,
                    securePayment: `Paga de forma segura con Tarjeta de Crédito o Débito o Banca por Internet a través de los Servidores Seguros de PayU Latam.`,
                    buyerInfo: `Información del comprador`,
                    buyerSameAsPatient: `La información del comprador es igual a la del paciente.`,
                    fullName: `Nombre completo`,
                    reservationUnderReview: `Tu reserva estará bajo revisión, el especialista se pondrá en contacto contigo para confirmar su cita.`,
                    clickOn: `Al hacer click en`,
                    privacyPolicy: `estarás aceptando nuestra`,
                    termsConditions: `Política de Privacidad`,
                    pay: `Pagar`,
                    book: `Reservar`,
                    noPaymentMethod: `Lo sentimos, el especialista en cuestión no cuenta con métodos de pago habilitados en este momento. Por favor, intente nuevamente en otro momento o seleccione otro especialista para programar su cita.`,
                    payAmount: `Valor a pagar`,
                    time: `Hora`,
                    date: `Fecha`,
                    appointmentType: `Tipo Cita`,
                    generalConsultation: `Consulta general (Valoración)`,
                    reservationDetails: `Detalles de la reserva`,
                    reservationConfirmation: `Confirmación Reserva`,
                    and: `y`,
                    termsAndConditions: `Términos y Condiciones`,
                    transactionSummary: `Resumen de la transacción`,
                    transactionStatus: `Estado de la transacción`,
                    transactionID: `ID de la transacción`,
                    transactionReference: `Referencia de la transacción`,
                    totalValue: `Valor total`,
                    currency: `Moneda`,
                    pendingTransactionMessage: `Su transaccion se encuentra pendiente por confirmación de PayU, para validar el estado de click en 'Consultar estado' o valide el estado en mis citas dando click`,
                    pendingTransactionMessageNotLogin: `Su transacción se encuentra pendiente de confirmación por parte de PayU. Para validar el estado, haga clic en 'Consultar estado' o valide el estado en 'Mis citas' iniciando sesión.`,
                    here: `Aqui`,
                    checkStatus: `Consultar estado`,
                    backToHome: `Volver al Inicio`,
                    signatureValidationFailed: `No se pudo validar la firma digital de la transacción.`,
                    pendingAppointmentMessage: `Su reserva se encuentra pendiente por confirmación por parte del especialista. Valide el estado en 'Mis citas' haciendo clic aquí.`,
                    pendingAppointmentMessageNotLogin: `Su reserva se encuentra pendiente de confirmación por parte del especialista. Valide el estado en 'Mis citas' iniciando sesión..`,
                    patientData: `Datos del Paciente`,
                    specialistData: `Datos del Especialista`,
                    appointmentData: `Datos de la cita`,
                    confirmationMessage: `Pronto el especialista se comunicará para confirmar.`,
                    appointmentPendingValidationMessage: `¡Su cita está pendiente de validación!`,
                    bookingInfo: `Información de la reserva`,
                    paymentReference: "Referencia de pago",
                    paymentType: "Tipo de pago",
                    amount: "Valor",
                    bookingStatus: "Estado Cita",
                    bookingDate: "Fecha cita",
                    paymentStatus: "Estado pago",
                    calendarLoadError: "No pudimos cargar el calendario...",
                    previousMonth: "Anterior mes",
                    nextMonth: "Próximo mes",
                    bookAppointment: "Reservar cita",
                    loginToContinuePayment: "¿Desea iniciar sesión para continuar con el pago?",
                    continueWithoutSession: "Continuar sin sesión",
                    fetchingProcedureInformation: "Obteniendo la información del procedimiento...",
                    noInformation: "Sin Información",
                    images: "Imágenes",
                    videos: "Videos",
                    knowMoreAboutOurSpecialists: "Conoce más de nuestros especialistas",
                    viewProfile: "Ver perfil",
                    sortBy: "Ordenar por nombre",
                    seeMore: "Ver más",
                    changePassword: "Cambiar contraseña",
                    confirmNewPassword: "Confirmar contraseña nueva",
                    newPassword: "Contraseña nueva",
                    currentPassword: "Contraseña actual",
                    passwordChanged: "Se ha cambiado la contraseña.",
                    newPasswordNotMatch: "La contraseña nueva no coincide.",
                    confirmNewPasswordRequired: "Ingrese la confirmación de la contraseña nueva.",
                    currentPasswordRequired: "Ingrese la contraseña actual.",
                    register: "Registrarme",
                    confirmPassword: "Confirmar Contraseña",
                    loginInfo: "Información de inicio sesión",
                    phoneMobile: "Teléfono/Celular",
                    gender: "Sexo",
                    identification: "Identificación",
                    select: "Seleccione",
                    personalInfo: "Información personal",
                    patientRegistration: "Registro Paciente",
                    enterPassword: "Ingrese una contraseña.",
                    passwordNotMatch: "La contraseña no coincide.",
                    invalidEmail: "El correo ingresado no es válido.",
                    requiredFields: "Todos los campos son obligatorios.",
                    registrationSuccess: "Su registro ha sido exitoso. Ya puede iniciar sesión y disfrutar de las ventajas que ofrece el tener cuenta con inmed.",
                    male: "Masculino",
                    female: "Femenino",
                    bestSpecialists: "Encuentra los mejores especialistas en Cirugía Plástica de la ciudad de Medellín",
                    allSpecialists: "Conoce todos nuestros especialistas",
                    whatProcedures: "¿Qué son los procedimientos corporales y faciales?",
                    plasticSurgeryInfo: "Si buscas realizar una liposucción, un aumento o reducción de senos o algún procedimiento estético en el rostro, ten presente que este debe ser realizado exclusivamente por un Cirujano Plástico, y en Inmed encontrarás a los mejores cirujanos certificados del país. Contáctanos y te ayudaremos en tu proceso de cambio y renovación. Te esperamos",
                    moreProcedures: "Conocer más procedimientos",
                    message: "Mensaje",
                    notValid: "no es válido",
                    emailEntered: "El correo ingresado",
                    messageNotSent: "No se pudo enviar el mensaje, intente más tarde.",
                    thankYou: "Gracias por contactar con nosotros.",
                    aboutUs: "¿Quiénes Somos?",
                    aboutUsContent: "Somos un grupo que conecta a pacientes de cirugía plástica con los mejores especialistas del país. Doctores que cumplen con todos los criterios médicos para ofrecer la mayor seguridad a todos los pacientes. Inmed en compañía de la Sociedad Colombiana de Cirugía Plástica (SCCP), el clúster de salud, y las clínicas habilitadas y acreditadas para procedimientos de cirugía plástica, estética y reconstructiva llevará el mejor respaldo y confianza a todos los pacientes tanto locales como internacionales.",
                    vision: "Visión",
                    visionContent: "Inmed será la plataforma líder a nivel mundial en la oferta y acceso a servicios médicos donde solo trabajaremos con las agremiaciones medicas de cada país para ofrecer la mayor confianza y calidad a nuestros pacientes.",
                    mission: "Misión",
                    missionContent: "Ofrecer los mejores especialistas de cada área de la salud por medio de plataformas tecnológicas que permitan a los usuarios la mejor experiencia en el acceso a los diferentes tratamientos que ofrece el mundo de la medicina.",
                    logout: "Cerrar sesión",
                    myAccount: "Mi Cuenta",
                    contactUs: "Contáctenos",
                    specialists: "Especialistas",
                    home: "Inicio",
                    logoutError: "No se pudo cerrar sesión, intente más tarde.",
                    ourSocialMedia: "Nuestras redes",
                    selectSpecialist: "Seleccionar Especialista",
                    website: "Página Web",
                    settings: "Configuración",
                    myAppointments: "Mis citas",
                    history: "Historial",
                    patients: "Pacientes",
                    agenda: "Agenda"
                }
            },
            it: {
                translation: {
                    asignamentAppointment: 'Prendere appuntamento',
                    loadingSpecialist: 'Caricamento dei migliori specialisti',
                    Warning: 'Avvertimento',
                    Accept: 'Accettare',
                    cancel: 'Annulla',
                    attention: 'Attenzione',
                    appointmentNotAssist: `L'appuntamento è stato aggiornato allo stato Non ho partecipato`,
                    appointmentConfirm: `L'appuntamento è stato aggiornato allo stato confermato`,
                    dataSentMail: `I tuoi dati sono stati inviati all'e-mail`,
                    userPwFail: 'Nome utente e/o password errati.',
                    pwNotRecovery: `Non è stato possibile eseguire il recupero della password.`,
                    login: `Login`,
                    user: `Utente`,
                    password: `Password`,
                    forgotPassword: `Hai dimenticato la tua password?`,
                    back: `Indietro`,
                    recoveryPassword: `Recupera la password`,
                    messageLogin: `Inserisci l'e-mail con cui ti trovi registrati sulla piattaforma, verranno inviati i passaggi a questa che deve seguire.`,
                    email: `e-mail`,
                    send: `Inviare`,
                    createAccount: `Crea un account`,
                    joinCommunity: `Unisciti alla comunità inmed e ottieni accesso a`,
                    scheduleAppointment: `Prenota in modo rapido e facile.`,
                    payAppointment: `Paga l'appuntamento virtualmente.`,
                    accessWaitingList: `Accesso alla lista d'attesa. (presto disponibile)`,
                    rateExperience: `Puoi valutare la tua esperienza con gli specialisti. (presto disponibile)`,
                    signUp: `Registrati`,
                    specialist: `Specialista`,
                    secretary: `Segretaria`,
                    patient: `Paziente`,
                    joinInmed: `Vuoi unirti a Inmed?`,
                    joinInmedText: `Fai parte della comunità di specialisti più affidabile, fatti conoscere e gestisci il tuo programma in modo più organizzato, con pagamenti online e molti altri strumenti, tutto su una piattaforma e con il miglior talento umano. Lasciaci i tuoi dati e ti forniremo tutti i dettagli.`,
                    join: `Unisciti`,
                    availableSchedule: "Orario disponibile",
                    from: "Da",
                    to: "A",
                    procedure: "Procedura",
                    city: "Città",
                    search: "Cerca",
                    map: "Carta geografica",
                    appointment: `Appuntamento`,
                    bookAnAppointment: `Prenota un appuntamento`,
                    shareProfile: `Condividi profilo`,
                    share: `Condividi`,
                    favorites: `Preferiti`,
                    addFavorites: `Aggiungi ai preferiti`,
                    aboutMe: `Chi sono`,
                    patientReviews: `Recensioni dei pazienti`,
                    procedures: `Procedure`,
                    studies: `Studi`,
                    awardsRecognitions: `Associazioni`,
                    contact: `Contatto`,
                    address: "Indirizzo",
                    phone: "Telefono",
                    reviewaEmpty: "Non sono state ancora fatte recensioni",
                    record: "Valutazione",
                    specialistInfoError: `Le informazioni sullo specialista non hanno potuto essere caricate. Si prega di riprovare più tardi.`,
                    consultingFavoriteSpecialist: `Consultazione del tuo specialista preferito....`,
                    searchValue: `Valore di Ricerca`,
                    selectField: `Seleziona Campo`,
                    patientInfo: `Informazioni del paziente`,
                    documentType: `Tipo di documento`,
                    nationalID: `Carta d'identità nazionale`,
                    PasaportID: `Pasaporte`,
                    foreignID: `Documento di identità straniero`,
                    civilRegistry: `Registro Civile`,
                    documentNumber: `Numero di documento`,
                    firstName: `Nome`,
                    lastName: `Cognome`,
                    selectPaymentMethod: `Seleziona un metodo di pagamento`,
                    securePayment: `Paga in modo sicuro con Carta di Credito o Bancomat o Internet Banking attraverso i Server Sicuri di PayU Latam.`,
                    buyerInfo: `Informazioni dell'acquirente`,
                    buyerSameAsPatient: `Le informazioni dell'acquirente sono le stesse delle informazioni del paziente.`,
                    fullName: `Nome completo`,
                    reservationUnderReview: `La tua prenotazione sarà sotto revisione, lo specialista ti contatterà per confermare il tuo appuntamento.`,
                    clickOn: `Cliccando su`,
                    privacyPolicy: `accetterai la nostra`,
                    termsConditions: `Informativa sulla privacy`,
                    pay: `Paga`,
                    book: `Prenota`,
                    noPaymentMethod: `Spiacenti, lo specialista in questione non ha metodi di pagamento abilitati al momento. Si prega di riprovare più tardi o selezionare un altro specialista per pianificare l'appuntamento.`,
                    payAmount: `Importo da pagare`,
                    time: `Ora`,
                    date: `Data`,
                    appointmentType: `Tipo di appuntamento`,
                    generalConsultation: `Consulta generale (Valutazione)`,
                    reservationDetails: `Dettagli della prenotazione`,
                    reservationConfirmation: `Conferma della prenotazione`,
                    and: `e`,
                    termsAndConditions: `Termini e condizioni`,
                    transactionSummary: `Sommario della transazione`,
                    transactionStatus: `Stato della transazione`,
                    transactionID: `ID della transazione`,
                    transactionReference: `Riferimento della transazione`,
                    totalValue: `Valore totale`,
                    currency: `Valuta`,
                    pendingTransactionMessage: `La tua transazione è in attesa di conferma da parte di PayU, per validare lo stato clicca su 'Verifica stato' o valida lo stato nelle mie prenotazioni cliccando`,
                    pendingTransactionMessageNotLogin: `La sua transazione è in attesa di conferma da parte di PayU. Per convalidare lo stato, fare clic su 'Verifica stato' o convalidare lo stato in 'I miei appuntamenti' effettuando l'accesso.`,
                    here: `Qui`,
                    checkStatus: `Verifica stato`,
                    backToHome: `Torna alla Home`,
                    signatureValidationFailed: `Impossibile convalidare la firma digitale della transazione.`,
                    pendingAppointmentMessage: `La sua prenotazione è in attesa di conferma da parte dello specialista. Conferma lo stato in 'I miei appuntamenti' facendo clic qui.`,
                    pendingAppointmentMessageNotLogin: `Il tuo appuntamento è in attesa di conferma da parte dello specialista. Conferma lo stato in 'I miei appuntamenti' effettuando l'accesso.`,
                    patientData: `Informazioni del paziente`,
                    specialistData: `Informazioni dello Specialista`,
                    appointmentData: `Dettagli dell'appuntamento`,
                    confirmationMessage: `Em breve, o especialista entrará em contato para confirmar.`,
                    appointmentPendingValidationMessage: `Il tuo appuntamento è in attesa di convalida!`,
                    bookingInfo: `Informazioni sulla prenotazione`,
                    paymentReference: "Riferimento pagamento",
                    paymentType: "Tipo di pagamento",
                    amount: "Importo",
                    bookingStatus: "Stato della prenotazione",
                    bookingDate: "Data della prenotazione",
                    paymentStatus: "Stato del pagamento",
                    calendarLoadError: "Non siamo riusciti a caricare il calendario...",
                    previousMonth: "Mese precedente",
                    nextMonth: "Mese successivo",
                    bookAppointment: "Prenota appuntamento",
                    loginToContinuePayment: "Desideri effettuare l'accesso per continuare con il pagamento?",
                    continueWithoutSession: "Continua senza sessione",
                    fetchingProcedureInformation: "Recupero delle informazioni sulla procedura...",
                    noInformation: "Nessuna informazione",
                    images: "Immagini",
                    videos: "Video",
                    knowMoreAboutOurSpecialists: "Scopri di più sui nostri specialisti",
                    viewProfile: "Vedi Profilo",
                    sortBy: "Ordina per nome",
                    seeMore: "Visualizza altro",
                    changePassword: "Modifica password",
                    confirmNewPassword: "Conferma nuova password",
                    newPassword: "Nuova password",
                    currentPassword: "Password attuale",
                    passwordChanged: "La tua password è stata modificata.",
                    newPasswordNotMatch: "La nuova password non corrisponde.",
                    confirmNewPasswordRequired: "Inserisci la conferma della nuova password.",
                    currentPasswordRequired: "Inserisci la password attuale.",
                    register: "Registrati",
                    confirmPassword: "Conferma Password",
                    loginInfo: "Informazioni di accesso",
                    phoneMobile: "Telefono/Cellulare",
                    gender: "Sesso",
                    identification: "Identificazione",
                    select: "Seleziona",
                    personalInfo: "Informazioni personali",
                    patientRegistration: "Registrazione paziente",
                    enterPassword: "Inserisci una password.",
                    passwordNotMatch: "Le password non corrispondono.",
                    invalidEmail: "L'e-mail inserita non è valida.",
                    requiredFields: "Tutti i campi sono obbligatori.",
                    registrationSuccess: "Registrazione avvenuta con successo. Ora puoi effettuare l'accesso e goderti i vantaggi di avere un account Inmed.",
                    male: "Maschile",
                    female: "Femminile",
                    bestSpecialists: "Trova i migliori specialisti in Chirurgia Plastica nella città di Medellín",
                    allSpecialists: "Scopri tutti i nostri specialisti",
                    whatProcedures: "Cosa sono i procedimenti corporei e facciali?",
                    plasticSurgeryInfo: "Se stai pensando di fare una liposuzione, un aumento o una riduzione del seno, o qualsiasi altro intervento estetico al viso, sappi che questo deve essere eseguito esclusivamente da un Chirurgo Plastico, e da Inmed troverai i migliori chirurghi certificati del paese. Contattaci e ti aiuteremo nel tuo processo di cambiamento e rinnovamento. Ti aspettiamo.",
                    moreProcedures: "Scopri di più sui procedimenti",
                    message: "Messaggio",
                    notValid: "non è valido",
                    emailEntered: "L'email inserita",
                    messageNotSent: "Impossibile inviare il messaggio, riprova più tardi.",
                    thankYou: "Grazie per averci contattato.",
                    aboutUs: "Chi Siamo",
                    aboutUsContent: "Siamo un gruppo che mette in contatto i pazienti di chirurgia plastica con i migliori specialisti del paese. Medici che soddisfano tutti i criteri medici per offrire la massima sicurezza a tutti i pazienti. Inmed insieme alla Società Colombiana di Chirurgia Plastica (SCCP), al cluster sanitario e alle cliniche autorizzate e accreditate per interventi di chirurgia plastica, estetica e ricostruttiva fornirà il migliore supporto e fiducia a tutti i pazienti, sia locali che internazionali.",
                    vision: "Visione",
                    visionContent: "Inmed sarà la piattaforma leader mondiale nella fornitura e nell'accesso ai servizi medici, dove lavoreremo solo con le associazioni mediche di ciascun paese per offrire la massima fiducia e qualità ai nostri pazienti.",
                    mission: "Missione",
                    missionContent: "Offrire i migliori specialisti in ciascuna area della salute attraverso piattaforme tecnologiche che consentano agli utenti di vivere la migliore esperienza nell'accesso ai diversi trattamenti offerti dal mondo della medicina.",
                    logout: "Logout",
                    myAccount: "Il Mio Account",
                    contactUs: "Contattaci",
                    specialists: "Specialisti",
                    home: "Home",
                    logoutError: "Impossibile effettuare il logout, si prega di riprovare più tardi.",
                    ourSocialMedia: "I nostri social media",
                    selectSpecialist: "Seleziona specialista",
                    website: "Sito Web",
                    settings: "Impostazioni",
                    myAppointments: "Le mie prenotazioni",
                    history: "Cronologia",
                    patients: "Pazienti",
                    agenda: "Agenda"
                }
            },
            fr: {
                translation: {
                    asignamentAppointment: 'Prendre un rendez-vous',
                    loadingSpecialist: 'Chargement des meilleurs spécialistes',
                    Warning: 'Avertissement',
                    Accept: 'Accepter',
                    cancel: 'Annuler',
                    attention: 'attention',
                    appointmentNotAssist: `Le rendez-vous a été mis à jour avec le statut Je n'ai pas assisté`,
                    appointmentConfirm: 'Le rendez-vous a été mis à jour au statut confirmé',
                    dataSentMail: `Vos données ont été envoyées à l'e-mail`,
                    userPwFail: `Nom d'utilisateur et/ou mot de passe incorrects.`,
                    pwNotRecovery: `La récupération du mot de passe n'a pas pu être effectuée.`,
                    login: `Commencer la session`,
                    user: `Utilisateur`,
                    password: `Mot de passe`,
                    forgotPassword: `Avez-vous oublié votre mot de passe?`,
                    back: `Retourner`,
                    recoveryPassword: `Récupérer le mot de passe`,
                    messageLogin: `Entrez l'email avec lequel vous êtes inscrit sur la plateforme, les démarches seront envoyées à celle-ci cela doit suivre.`,
                    email: `email`,
                    send: `Envoyer`,
                    createAccount: `Créer un compte`,
                    joinCommunity: `Rejoignez la communauté inmed et accédez à`,
                    scheduleAppointment: `Planifiez rapidement et facilement.`,
                    payAppointment: `Payez votre rendez-vous virtuellement.`,
                    accessWaitingList: `Accès à la liste d'attente. (bientôt disponible)`,
                    rateExperience: `Vous pouvez évaluer votre expérience avec les spécialistes. (bientôt disponible)`,
                    signUp: `S'inscrire`,
                    specialist: `Spécialiste`,
                    secretary: `Secrétaire`,
                    patient: `Patient`,
                    joinInmed: `Vous voulez rejoindre Inmed ?`,
                    joinInmedText: `Faites partie de la communauté de spécialistes la plus fiable, faites-vous connaître et gérez votre emploi du temps de manière plus organisée, avec des paiements en ligne et de nombreux autres outils, le tout sur une même plateforme et avec le meilleur talent humain. Laissez-nous vos coordonnées et nous vous fournirons toutes les informations.`,
                    join: `Rejoindre`,
                    availableSchedule: "Horaires disponibles",
                    from: "De",
                    to: "À",
                    procedure: "Procédure",
                    city: "Ville",
                    search: "Chercher",
                    map: "Carte",
                    appointment: `Rendez-vous`,
                    bookAnAppointment: `Prendre rendez-vous`,
                    shareProfile: `Partager le profil`,
                    share: `Partager`,
                    favorites: `Favoris`,
                    addFavorites: `Ajouter aux Favoris`,
                    aboutMe: `À propos de moi`,
                    patientReviews: `Avis des patients`,
                    procedures: `Procédures`,
                    studies: `Études`,
                    awardsRecognitions: `Les associations`,
                    contact: `Contact`,
                    address: "Adresse",
                    phone: "Téléphone",
                    reviewaEmpty: "Aucune évaluation n'a encore été faite",
                    record: "Classement",
                    specialistInfoError: `Les informations sur le spécialiste n'ont pas pu être chargées. Veuillez réessayer plus tard.`,
                    consultingFavoriteSpecialist: `Consultation de votre spécialiste favori....`,
                    searchValue: `Valeur de recherche`,
                    selectField: `Sélectionnez le champ`,
                    patientInfo: `Informations sur le patient`,
                    documentType: `Type de document`,
                    nationalID: `Carte d'identité nationale`,
                    PasaportID: `Pasaporte`,
                    foreignID: `Carte d'identité étrangère`,
                    civilRegistry: `Registre Civil`,
                    documentNumber: `Numéro de document`,
                    firstName: `Prénom`,
                    lastName: `Nom de famille`,
                    selectPaymentMethod: `Sélectionnez un mode de paiement`,
                    securePayment: `Payez en toute sécurité par carte de crédit ou de débit ou par Internet Banking via les serveurs sécurisés de PayU Latam.`,
                    buyerInfo: `Informations sur l'acheteur`,
                    buyerSameAsPatient: `Les informations sur l'acheteur sont les mêmes que celles sur le patient.`,
                    fullName: `Nom complet`,
                    reservationUnderReview: `Votre réservation sera examinée, le spécialiste vous contactera pour confirmer votre rendez-vous.`,
                    clickOn: `En cliquant sur`,
                    privacyPolicy: `vous accepterez notre`,
                    termsConditions: `Politique de confidentialité`,
                    pay: `Payer`,
                    book: `Réserver`,
                    noPaymentMethod: `Désolé, le spécialiste en question n'a pas de modes de paiement activés pour le moment. Veuillez réessayer plus tard ou sélectionner un autre spécialiste pour planifier votre rendez-vous.`,
                    payAmount: `Montant à payer`,
                    time: `Heure`,
                    date: `Date`,
                    appointmentType: `Type de rendez-vous`,
                    generalConsultation: `Consultation générale (Évaluation)`,
                    reservationDetails: `Détails de la réservation`,
                    reservationConfirmation: `Confirmation de la réservation`,
                    and: `et`,
                    termsAndConditions: `Termes et conditions`,
                    transactionSummary: `Résumé de la transaction`,
                    transactionStatus: `État de la transaction`,
                    transactionID: `ID de la transaction`,
                    transactionReference: `Référence de la transaction`,
                    totalValue: `Valeur totale`,
                    currency: `Devise`,
                    pendingTransactionMessage: `Votre transaction est en attente de confirmation de PayU, pour valider le statut, cliquez sur 'Vérifier le statut' ou validez le statut dans mes rendez-vous en cliquant`,
                    pendingTransactionMessageNotLogin: `Votre transaction est en attente de confirmation de la part de PayU. Pour valider le statut, cliquez sur 'Vérifier le statut' ou validez le statut dans 'Mes rendez-vous' en vous connectant.`,
                    here: `Ici`,
                    checkStatus: `Vérifier le statut`,
                    backToHome: `Retour à l'accueil`,
                    signatureValidationFailed: `La signature numérique de la transaction n'a pas pu être validée.`,
                    pendingAppointmentMessage: `Votre rendez-vous est en attente de confirmation par le spécialiste. Validez le statut dans 'Mes rendez-vous' en cliquant ici.`,
                    pendingAppointmentMessageNotLogin: `Votre rendez-vous est en attente de confirmation de la part du spécialiste. Validez le statut dans 'Mes rendez-vous' en vous connectant.`,
                    patientData: `Informations du patient`,
                    specialistData: `Informations du spécialiste`,
                    appointmentData: `Détails du rendez-vous`,
                    confirmationMessage: `Le spécialiste vous contactera bientôt pour confirmer.`,
                    appointmentPendingValidationMessage: `Votre rendez-vous est en attente de validation!`,
                    bookingInfo: `Informations sur la réservation`,
                    paymentReference: "Référence de paiement",
                    paymentType: "Type de paiement",
                    amount: "Montant",
                    bookingStatus: "Statut de la réservation",
                    bookingDate: "Date de la réservation",
                    paymentStatus: "Statut du paiement",
                    calendarLoadError: "Nous n'avons pas pu charger le calendrier...",
                    previousMonth: "Mois précédent",
                    nextMonth: "Mois suivant",
                    bookAppointment: "Prendre rendez-vous",
                    loginToContinuePayment: "Voulez-vous vous connecter pour continuer le paiement?",
                    continueWithoutSession: "Continuer sans session",
                    fetchingProcedureInformation: "Obtention des informations sur la procédure...",
                    noInformation: "Aucune information",
                    images: "Images",
                    videos: "Vidéos",
                    knowMoreAboutOurSpecialists: "En savoir plus sur nos spécialistes",
                    viewProfile: "Voir le profil",
                    sortBy: "Trier par nom",
                    seeMore: "Voir plus",
                    changePassword: "Changer le mot de passe",
                    confirmNewPassword: "Confirmez le nouveau mot de passe",
                    newPassword: "Nouveau mot de passe",
                    currentPassword: "Mot de passe actuel",
                    passwordChanged: "Votre mot de passe a été modifié.",
                    newPasswordNotMatch: "Le nouveau mot de passe ne correspond pas.",
                    confirmNewPasswordRequired: "Veuillez entrer la confirmation du nouveau mot de passe.",
                    currentPasswordRequired: "Veuillez entrer le mot de passe actuel.",
                    register: "S'inscrire",
                    confirmPassword: "Confirmer le mot de passe",
                    loginInfo: "Informations de connexion",
                    phoneMobile: "Téléphone/Portable",
                    gender: "Sexe",
                    identification: "Identification",
                    select: "Sélectionner",
                    personalInfo: "Informations personnelles",
                    patientRegistration: "Enregistrement du patient",
                    enterPassword: "Entrez un mot de passe.",
                    passwordNotMatch: "Les mots de passe ne correspondent pas.",
                    invalidEmail: "L'e-mail saisi n'est pas valide.",
                    requiredFields: "Tous les champs sont obligatoires.",
                    registrationSuccess: "Votre inscription a été effectuée avec succès. Vous pouvez maintenant vous connecter et profiter des avantages d'avoir un compte Inmed.",
                    male: "Masculin",
                    female: "Féminin",
                    bestSpecialists: "Trouvez les meilleurs spécialistes en chirurgie plastique de la ville de Medellín",
                    allSpecialists: "Découvrez tous nos spécialistes",
                    whatProcedures: "Quels sont les procédures corporelles et faciales?",
                    plasticSurgeryInfo: "Si vous envisagez de subir une liposuccion, une augmentation ou une réduction mammaire, ou toute autre procédure esthétique du visage, sachez que celle-ci doit être réalisée exclusivement par un chirurgien plastique, et chez Inmed, vous trouverez les meilleurs chirurgiens certifiés du pays. Contactez-nous et nous vous aiderons dans votre processus de changement et de renouvellement. Nous vous attendons.",
                    moreProcedures: "En savoir plus sur les procédures",
                    message: "Message",
                    notValid: "n'est pas valide",
                    emailEntered: "L'e-mail saisi",
                    messageNotSent: "Le message n'a pas pu être envoyé, veuillez réessayer plus tard.",
                    thankYou: "Merci de nous avoir contactés.",
                    aboutUs: "Qui sommes-nous ?",
                    aboutUsContent: "Nous sommes un groupe qui met en relation les patients de chirurgie plastique avec les meilleurs spécialistes du pays. Des médecins qui répondent à tous les critères médicaux pour offrir la plus grande sécurité à tous les patients. Inmed, en collaboration avec la Société colombienne de chirurgie plastique (SCCP), le pôle de santé et les cliniques autorisées et accréditées pour les interventions de chirurgie plastique, esthétique et reconstructrice, apportera le meilleur soutien et la meilleure confiance à tous les patients, tant locaux qu'internationaux.",
                    vision: "Vision",
                    visionContent: "Inmed sera la plateforme leader au niveau mondial dans l'offre et l'accès aux services médicaux où nous travaillerons uniquement avec les associations médicales de chaque pays pour offrir la plus grande confiance et qualité à nos patients.",
                    mission: "Mission",
                    missionContent: "Offrir les meilleurs spécialistes dans chaque domaine de la santé grâce à des plates-formes technologiques permettant aux utilisateurs de vivre la meilleure expérience dans l'accès aux différents traitements offerts par le monde de la médecine.",
                    logout: "Se déconnecter",
                    myAccount: "Mon compte",
                    contactUs: "Contactez-nous",
                    specialists: "Spécialistes",
                    home: "Accueil",
                    logoutError: "Déconnexion impossible, veuillez réessayer plus tard.",
                    ourSocialMedia: "Nos réseaux sociaux",
                    selectSpecialist: "Sélectionner un spécialiste",
                    website: "Site Web",
                    settings: "Paramètres",
                    myAppointments: "Mes rendez-vous",
                    history: "Historique",
                    patients: "Patients",
                    agenda: "Agenda"

                }
            },
            pt: {
                translation: {
                    asignamentAppointment: 'Marcar consulta',
                    loadingSpecialist: 'Carregando os principais especialistas',
                    Warning: 'Aviso',
                    Accept: 'Aceitar',
                    cancel: 'Cancelar',
                    attention: 'atenção',
                    appointmentNotAssist: 'O compromisso foi atualizado para o status Não compareceu',
                    appointmentConfirm: 'O compromisso foi atualizado para o status confirmado',
                    dataSentMail: 'Seus dados foram enviados para o email',
                    userPwFail: 'Nome de usuário e/ou senha incorretos.',
                    pwNotRecovery: `A recuperação da senha não pôde ser executada.`,
                    login: `Iniciar sessão`,
                    user: `Usuário `,
                    password: `Senha`,
                    forgotPassword: `Esqueceu sua senha?`,
                    back: `Volte`,
                    recoveryPassword: `Recuperar senha`,
                    messageLogin: `Digite o e-mail com o qual você está cadastrado na plataforma, os passos serão enviados para este isso deve seguir.`,
                    email: `e-mail`,
                    send: `Enviar`,
                    createAccount: `Criar conta`,
                    joinCommunity: `Faça parte da comunidade inmed e tenha acesso a`,
                    scheduleAppointment: `Agende de forma rápida e fácil.`,
                    payAppointment: `Pague sua consulta virtualmente.`,
                    accessWaitingList: `Acesso à lista de espera. (em breve)`,
                    rateExperience: `Você pode avaliar sua experiência com os especialistas. (em breve)`,
                    signUp: `Registrar`,
                    specialist: `Especialista`,
                    secretary: `Secretaria`,
                    patient: `Paciente`,
                    joinInmed: `Quer fazer parte do Inmed?`,
                    joinInmedText: `Faça parte da comunidade de especialistas mais confiável, torne-se conhecido e gerencie sua agenda de forma mais organizada, com pagamentos online e muitas outras ferramentas, tudo em uma única plataforma e com o melhor talento humano. Deixe-nos suas informações e forneceremos todos os detalhes.`,
                    join: `Participar`,
                    availableSchedule: "Agenda Disponível",
                    from: "De",
                    to: "Até",
                    procedure: "Procedimento",
                    city: "Cidade",
                    search: "Buscar",
                    map: "Mapa",
                    appointment: `Consulta`,
                    bookAnAppointment: `Agendar consulta`,
                    shareProfile: `Compartilhar perfil`,
                    share: `Compartilhar`,
                    favorites: `Favoritos`,
                    addFavorites: `Adicionar aos favoritos`,
                    aboutMe: `Sobre mim`,
                    patientReviews: `Avaliações dos Pacientes`,
                    procedures: `Procedimentos`,
                    studies: `Estudos`,
                    awardsRecognitions: `Associações`,
                    contact: `Contato`,
                    address: "Endereço",
                    phone: "Telefone",
                    reviewaEmpty: "Ainda não foram feitas avaliações",
                    record: "Classificação",
                    specialistInfoError: `As informações do especialista não puderam ser carregadas. Por favor, tente novamente mais tarde.`,
                    consultingFavoriteSpecialist: `Consultando seu especialista favorito....`,
                    searchValue: `Valor da Busca`,
                    selectField: `Selecionar Campo`,
                    patientInfo: `Informações do paciente`,
                    documentType: `Tipo de documento`,
                    nationalID: `Identidade Nacional`,
                    PasaportID: `Pasaporte`,
                    foreignID: `Identificação Estrangeira`,
                    civilRegistry: `Registro Civil`,
                    documentNumber: `Número do documento`,
                    firstName: `Nome`,
                    lastName: `Sobrenome`,
                    selectPaymentMethod: `Selecione um método de pagamento`,
                    securePayment: `Pague com segurança com Cartão de Crédito ou Débito ou Internet Banking através dos Servidores Seguros da PayU Latam.`,
                    buyerInfo: `Informações do comprador`,
                    buyerSameAsPatient: `As informações do comprador são as mesmas que as do paciente.`,
                    fullName: `Nome Completo`,
                    reservationUnderReview: `Sua reserva estará sob revisão, o especialista entrará em contato com você para confirmar seu agendamento.`,
                    clickOn: `Ao clicar em`,
                    privacyPolicy: `você estará aceitando nossa`,
                    termsConditions: `Política de Privacidade`,
                    pay: `Pagar`,
                    book: `Reservar`,
                    noPaymentMethod: `Desculpe, o especialista em questão não possui métodos de pagamento habilitados no momento. Por favor, tente novamente mais tarde ou selecione outro especialista para agendar sua consulta.`,
                    payAmount: `Valor a pagar`,
                    time: `Hora`,
                    date: `Data`,
                    appointmentType: `Tipo de Consulta`,
                    generalConsultation: `Consulta Geral (Avaliação)`,
                    reservationDetails: `Detalhes da reserva`,
                    reservationConfirmation: `Confirmação da Reserva`,
                    and: `e`,
                    termsAndConditions: `Termos e Condições`,
                    transactionSummary: `Resumo da transação`,
                    transactionStatus: `Status da transação`,
                    transactionID: `ID da transação`,
                    transactionReference: `Referência da transação`,
                    totalValue: `Valor total`,
                    currency: `Moeda`,
                    pendingTransactionMessage: `Sua transação está pendente de confirmação da PayU, para validar o status, clique em 'Verificar status' ou valide o status em meus compromissos clicando`,
                    pendingTransactionMessageNotLogin: `Sua transação está pendente de confirmação pela PayU. Para validar o status, clique em 'Verificar status' ou valide o status em 'Meus compromissos' fazendo login.`,
                    here: `Aqui`,
                    checkStatus: `Verificar status`,
                    backToHome: `Voltar para o Início`,
                    signatureValidationFailed: `Não foi possível validar a assinatura digital da transação.`,
                    pendingAppointmentMessage: `Seu compromisso está pendente de confirmação pelo especialista. Valide o status em 'Meus compromissos' clicando aqui.`,
                    pendingAppointmentMessageNotLogin: `Seu compromisso está pendente de confirmação pelo especialista. Valide o status em 'Meus compromissos' fazendo login.`,
                    patientData: `Dados do Paciente`,
                    specialistData: `Dados do Especialista`,
                    appointmentData: `Detalhes do Compromisso`,
                    confirmationMessage: `Em breve, o especialista entrará em contato para confirmar.`,
                    appointmentPendingValidationMessage: `Seu compromisso está pendente de validação!`,
                    bookingInfo: `Informações da reserva`,
                    paymentReference: "Referência de pagamento",
                    paymentType: "Tipo de pagamento",
                    amount: "Valor",
                    bookingStatus: "Status da reserva",
                    bookingDate: "Data da reserva",
                    paymentStatus: "Status do pagamento",
                    calendarLoadError: "Não foi possível carregar o calendário...",
                    previousMonth: "Mês anterior",
                    nextMonth: "Próximo mês",
                    bookAppointment: "Agendar consulta",
                    loginToContinuePayment: "Deseja fazer login para continuar com o pagamento?",
                    continueWithoutSession: "Continuar sem sessão",
                    fetchingProcedureInformation: "Obtendo informações sobre o procedimento...",
                    noInformation: "Sem Informações",
                    images: "Imagens",
                    videos: "Vídeos",
                    knowMoreAboutOurSpecialists: "Saiba mais sobre nossos especialistas",
                    viewProfile: "Ver Perfil",
                    sortBy: "Ordenar por nome",
                    seeMore: "Ver mais",
                    changePassword: "Alterar senha",
                    confirmNewPassword: "Confirmar nova senha",
                    newPassword: "Nova senha",
                    currentPassword: "Senha atual",
                    passwordChanged: "Sua senha foi alterada.",
                    newPasswordNotMatch: "A nova senha não corresponde.",
                    confirmNewPasswordRequired: "Por favor, insira a confirmação da nova senha.",
                    currentPasswordRequired: "Por favor, insira a senha atual.",
                    register: "Registrar",
                    confirmPassword: "Confirmar Senha",
                    loginInfo: "Informações de login",
                    phoneMobile: "Telefone/Celular",
                    gender: "Gênero",
                    identification: "Identificação",
                    select: "Selecione",
                    personalInfo: "Informações pessoais",
                    patientRegistration: "Cadastro do paciente",
                    enterPassword: "Digite uma senha.",
                    passwordNotMatch: "As senhas não coincidem.",
                    invalidEmail: "O e-mail inserido não é válido.",
                    requiredFields: "Todos os campos são obrigatórios.",
                    registrationSuccess: "Registro bem-sucedido. Agora você pode fazer login e aproveitar os benefícios de ter uma conta Inmed.",
                    male: "Masculino",
                    female: "Feminino",
                    bestSpecialists: "Encontre os melhores especialistas em Cirurgia Plástica na cidade de Medellín",
                    allSpecialists: "Conheça todos os nossos especialistas",
                    whatProcedures: "O que são procedimentos corporais e faciais?",
                    plasticSurgeryInfo: "Se você está pensando em fazer uma lipoaspiração, um aumento ou redução de mama, ou qualquer outro procedimento estético facial, saiba que isso deve ser feito exclusivamente por um Cirurgião Plástico, e na Inmed você encontrará os melhores cirurgiões certificados do país. Entre em contato conosco e ajudaremos você em seu processo de mudança e renovação. Esperamos por você.",
                    moreProcedures: "Conheça mais procedimentos",
                    message: "Mensagem",
                    notValid: "não é válido",
                    emailEntered: "O e-mail inserido",
                    messageNotSent: "Não foi possível enviar a mensagem, tente novamente mais tarde.",
                    thankYou: "Obrigado por entrar em contato conosco.",
                    aboutUs: "Quem Somos",
                    aboutUsContent: "Somos um grupo que conecta pacientes de cirurgia plástica com os melhores especialistas do país. Médicos que atendem a todos os critérios médicos para oferecer a maior segurança a todos os pacientes. A Inmed, juntamente com a Sociedade Colombiana de Cirurgia Plástica (SCCP), o cluster de saúde e as clínicas autorizadas e credenciadas para procedimentos de cirurgia plástica, estética e reconstrutiva, proporcionará o melhor suporte e confiança a todos os pacientes, tanto locais quanto internacionais.",
                    vision: "Visão",
                    visionContent: "A Inmed será a plataforma líder mundial no fornecimento e acesso a serviços médicos, onde trabalharemos apenas com as associações médicas de cada país para oferecer a maior confiança e qualidade aos nossos pacientes.",
                    mission: "Missão",
                    missionContent: "Oferecer os melhores especialistas em cada área da saúde por meio de plataformas tecnológicas que permitam aos usuários a melhor experiência no acesso aos diferentes tratamentos oferecidos pelo mundo da medicina.",
                    logout: "Sair",
                    myAccount: "Minha Conta",
                    contactUs: "Contate-Nos",
                    specialists: "Especialistas",
                    home: "Início",
                    logoutError: "Não foi possível fazer logout, tente novamente mais tarde.",
                    ourSocialMedia: "Nossas redes sociais",
                    selectSpecialist: "Selecionar Especialista",
                    website: "Site",
                    settings: "Configurações",
                    myAppointments: "Minhas Consultas",
                    history: "Histórico",
                    patients: "Pacientes",
                    agenda: "Agenda"
                },
            },
        }
    });

export default i18n;