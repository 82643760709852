import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import {
  GoogleReCaptchaProvider,
  useGoogleReCaptcha,
} from "react-google-recaptcha-v3";
import { useLocation, useNavigate } from "react-router-dom";
import { Md5 } from "ts-md5";
import { IWarning, Message } from "../../components/message";
import { useAuth } from "../../middlewares/auth";
import HttpService from "../../services/http";
import {
  createPatient,
  createPayment,
  retrieveUser,
  updateAppoinment,
} from "../../services/payment";
import { Buyer, Payer } from "../../types/payment";
import useFetchTypePay from "../panel/pages/schedule/components/typepay/hooks/useFetchTypePay";
import "./purchase.css";
import { useTranslation } from "react-i18next";
import Loader from "../../components/Loader";

function ReCAPTCHAWrapper({
  children,
  onVerify,
}: {
  children: React.ReactNode;
  onVerify: (token: string) => void;
}) {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [recaptchaToken, setRecaptchaToken] = useState<string>("");

  useEffect(() => {
    if (!recaptchaToken && executeRecaptcha)
      executeRecaptcha("checkout").then((token) => {
        setRecaptchaToken(token);
        onVerify(token);
      });
  }, [executeRecaptcha, onVerify, recaptchaToken]);

  return <>{children}</>;
}

function Purchasepage(): JSX.Element {
  const navigate = useNavigate();
  const { i18n, t } = useTranslation();
  const { state: stateLocation } = useLocation();
  const { state } = useAuth();

  const userLoged = state?.isLoged ?? false;
  const [payPayu, setPayPayu] = useState(false);
  const [paySite, setPaySite] = useState(false);
  const [wompi, setWompi] = useState(false);
  const [userId, setUserId] = useState<string | undefined>(state?.userId);
  const [isInfoDuplicate, setIsInfoDuplicate] = useState(true);
  const [dateEnd, setDateEnd] = useState(getDateExpire());


  const appointmentType = stateLocation?.appointment_type;
  const price = stateLocation?.price;
  const currency = stateLocation?.currency;
  const specialist = stateLocation?.specialist;
  const selectAppointment = stateLocation?.selectAppointment;

  const { typePay } = useFetchTypePay(specialist.id);
  const [isLoading, setIsLoading] = useState(false);

  const [selectedPayment, setSelectedPayment] = useState<string>("wompi");

  const [buyerInfo, setBuyerInfo] = useState<Buyer>({
    email: "",
    fullName: "",
    telephone: "",
  });

  const [payerInfo, setPayerInfo] = useState<Payer>({
    document: "",
    documentType: "17",
    email: "",
    lastName: "",
    name: "",
    phone: "",
  });
  const [configure, setConfigure] = useState(true);

  const [referenceCode] = useState<string>(
    `${dayjs().format("YYYYMMDDHHmmss")}`
  );
  const [signature] = useState<string>(
    Md5.hashStr(
      `${process.env.REACT_APP_PAYU_API_KEY}~${process.env.REACT_APP_PAYU_MERCHANT_ID}~${referenceCode}~${price}~${currency}`
    )
  );

  const [warning, setWarning] = useState<IWarning>({
    show: false,
    message: "",
  });

  const [isValidReCaptcha, setIsValidReCaptcha] = useState<boolean>(false);

  useEffect(() => {
    if (typePay) {
      const selectedTypes = typePay.filter(
        (type) => type.specialist_id === specialist.id
      );

      const typeIds = selectedTypes.map((type) => type.pay_id);

      if (selectedTypes.length === 0) {
        setConfigure(false);
      } else {
        setConfigure(true);

        if (typeIds.includes(1)) {
          setPaySite(true);
          setSelectedPayment("site");
        }

        if (typeIds.includes(2)) {
          setPayPayu(true);
          setSelectedPayment("payu");
        }

        if (typeIds.includes(3)) {
          setWompi(true);
          setSelectedPayment("wompi");
        }
      }
    }
  }, [typePay]);

  useEffect(() => {
    if (userLoged)
      retrieveUser(userId).then((result) => {
        const { data } = result;
        if (data) {
          setUserId(data?.id);
          setBuyerInfo({
            email: data?.email ?? "",
            fullName: `${data?.first_name ?? ""} ${data?.last_name ?? ""}`,
            telephone: data?.phone ?? "",
          });
          setPayerInfo({
            document: data?.document ?? "",
            documentType: data?.document_type_id ?? "17",
            email: data?.email ?? "",
            lastName: data?.last_name ?? "",
            name: data?.first_name ?? "",
            phone: data?.phone ?? "",
          });
        }
      });
  }, [userLoged]);

  async function handlePay(e: any) {
    if (!isValidReCaptcha) return;

    e.preventDefault();
    setIsLoading(true);

    let patientId: string | null | undefined = userId;
    const typepayments = selectedPayment === "wompi" ? 3 : 1;
    let token = `${referenceCode}${price * 100}${currency}`;
    try {
      if (!patientId) {
        createPatient(payerInfo)
          .then((createdPatientId) => {
            patientId = createdPatientId;
            return updateAppoinment(
              stateLocation?.appointment?.id,
              patientId,
              21
            );
          })
          .then(() => {
            debugger
            createPayment(
              price,
              stateLocation?.appointment?.id,
              referenceCode,
              typepayments,
              token
            ).then((response) => {
              setIsLoading(false);
              if (selectedPayment === "payu") {
                e.target?.submit();
              } else if (selectedPayment === "wompi") {
                let sg = response.data?.token;
                setIntegrity(sg);
              }
            })
              .catch((error: any) => {
                setIsLoading(false);
                setWarning({
                  show: true,
                  message:
                    error.message ??
                    "Se presento un problema , intenta nuevamente.",
                });
              });
          })
          .catch((error: any) => {
            setIsLoading(false);
            setWarning({
              show: true,
              message:
                error.message ??
                "Se presento un problema , intenta nuevamente.",
            });
          });
      } else {
        await updateAppoinment(stateLocation?.appointment?.id, patientId, 21);
        createPayment(
          price,
          stateLocation?.appointment?.id,
          referenceCode,
          typepayments,
          token
        ).then((response) => {
          setIsLoading(false);
          if (selectedPayment === "payu") {
            e.target?.submit();
          } else if (selectedPayment === "wompi") {
            let sg = response.data?.token;
            setIntegrity(sg);
          }
        })

      }
    } catch (error: any) {
      setIsLoading(false);
      console.error("Hubo un error al procesar la solicitud:", error.message);
    }
  }



  async function handleReserve(e: any) {
    e.preventDefault();
    debugger;
    if (!isValidReCaptcha) return;

    setIsLoading(true);

    let patientId: string | null | undefined = userId;
    const typepayments = 2;

    try {
      if (!patientId) {
        const createdPatientId = await createPatient(payerInfo);
        patientId = createdPatientId + "" ?? "";
      }

      const createpayment = await createPayment(price, stateLocation?.appointment?.id, referenceCode, typepayments, "");
      const responseAppointment = await updateAppoinment(stateLocation?.appointment?.id, patientId, 22)

      await
        setIsLoading(false);
      navigate("/purchase/responseSite", {
        preventScrollReset: true,
        state: {
          stateLocation: stateLocation,
          payerInfo: payerInfo,
          reserve: true,
        },
        replace: false,
      });
    } catch (error: any) {
      setIsLoading(false);
      setWarning({
        show: true,
        message:
          error.message ??
          "Se presento un problema , intenta nuevamente.",
      });
    }
  }

  function getDateExpire() {
    let fechaActual = new Date();
    let fechaExpiracion = new Date(fechaActual.getTime() + 24 * 60 * 60 * 1000);

    let expirationDate = fechaExpiracion.toISOString();
    return expirationDate;
  }
  function setIntegrity(integrity:any){
    
    let scriptbuton: any;

    scriptbuton = document.createElement("script");

    scriptbuton.src = process.env.REACT_APP_WOMPI_URL ?? "";
    scriptbuton.setAttribute("data-render", "button");
    scriptbuton.setAttribute("data-public-key", process.env.REACT_APP_WOMPI_KEY ?? "");
    scriptbuton.setAttribute("data-currency", currency);
    scriptbuton.setAttribute("data-amount-in-cents", `${price * 100}`);
    scriptbuton.setAttribute("data-reference", referenceCode);
    scriptbuton.setAttribute("data-signature:integrity", integrity ?? "");
    scriptbuton.setAttribute("data-redirect-url", process.env.REACT_APP_WOMPI_RESPONSE ?? "");
    document.body.appendChild(scriptbuton);
    setTimeout(() => {
      const button = document.querySelector("[class='waybox-button']");
      if (button) {
        (button as HTMLElement).click();
        document.body.removeChild(button);
        document.body.removeChild(scriptbuton);
      }
    }, 1000);
}
  useEffect(() => {
    if (isInfoDuplicate) {
      setBuyerInfo((prev) => ({
        ...prev,
        fullName: payerInfo.name + " " + payerInfo.lastName,
        email: payerInfo.email,
        telephone: payerInfo.phone,
      }));
    }
  }, [
    isInfoDuplicate,
    payerInfo?.name,
    payerInfo?.lastName,
    payerInfo?.email,
    payerInfo.phone,
  ]);

  const handleChangeisInfoCheck = (event: any) => {
    const isChecked = event.target.checked;

    setIsInfoDuplicate(isChecked);
    if (isChecked) {
      setBuyerInfo((prev) => ({
        ...prev,
        fullName: payerInfo.name + " " + payerInfo.lastName,
        email: payerInfo.email,
        telephone: payerInfo.phone,
      }));
    }
  };

  function handleVerify(token: string) {
    setIsValidReCaptcha(false);
    HttpService.post("captcha", { token })
      ?.then((result) => {
        if (result.status)
          setIsValidReCaptcha(result?.data?.success as boolean);
      })
      ?.catch(() => setIsValidReCaptcha(false));
  }

  const convertirRangoHoraAMPM = (rangoHora24: string) => {
    // Dividir el rango de horas en dos horas individuales
    const [horaInicio, horaFin] = rangoHora24.split("-");

    // Parsear las horas con Day.js
    const horaInicioParseada = dayjs(horaInicio, "HH:mm");
    const horaFinParseada = dayjs(horaFin, "HH:mm");

    // Formatear las horas en formato de 12 horas con 'am/pm'
    const horaInicio12 = horaInicioParseada.format("hh:mm A");
    const horaFin12 = horaFinParseada.format("hh:mm A");

    // Retornar el rango de horas en formato de 12 horas
    return `${horaInicio12} - ${horaFin12}`;
  };




  return (
    <>
      <GoogleReCaptchaProvider
        reCaptchaKey={process.env.REACT_APP_RECAPTCHA_KEY_WEB_SITE ?? ""}
      >
        <ReCAPTCHAWrapper onVerify={handleVerify}>
          <Container fluid style={{ padding: "30px" }}>
            <Form
              action={process.env.REACT_APP_PAYU_URL}
              method="POST"
              onSubmit={
                selectedPayment === "payu"
                  ? handlePay
                  : handleReserve
              }
            >
              <Form.Control
                type="hidden"
                name="merchantId"
                defaultValue={process.env.REACT_APP_PAYU_MERCHANT_ID}
              />
              <Form.Control
                type="hidden"
                name="accountId"
                defaultValue={process.env.REACT_APP_PAYU_ACCOUNT_ID}
              />
              <Form.Control
                name="responseUrl"
                type="hidden"
                value={process.env.REACT_APP_PAYU_RESPONSE}
              />
              <Form.Control
                name="confirmationUrl"
                type="hidden"
                value={process.env.REACT_APP_PAYU_CONFIRMATION}
              />
              <Form.Control type="hidden" name="test" defaultValue="0" />
              <Form.Control type="hidden" name="amount" defaultValue={price} />
              <Form.Control
                type="hidden"
                name="description"
                defaultValue={"pago agendamiento inmed Consulta Valoración"}
              />
              <Form.Control type="hidden" name="tax" defaultValue="0" />
              <Form.Control
                type="hidden"
                name="taxReturnBase"
                defaultValue="0"
              />
              <Form.Control
                type="hidden"
                name="currency"
                defaultValue={currency}
              />
              <Form.Control
                type="hidden"
                name="signature"
                defaultValue={signature}
              />

              <Form.Control
                type="hidden"
                name="expirationDate"
                defaultValue={getDateExpire()}
              />

              <Row>
                <Col xs sm md lg>
                  <h4 className="title-primary">
                    {t("reservationConfirmation")}
                  </h4>
                  <hr className="hr"></hr>
                </Col>
              </Row>
              <Row>
                <Col xs={12} sm={12} md={4} lg={4}>
                  <fieldset>
                    <legend>{t("reservationDetails")}</legend>
                    <Row>
                      <Col xs={12} sm={5} md={3} lg={3} xl={3} xxl={3}>
                        <img className="photo" src={specialist?.photo} />
                      </Col>
                      <Col xs={12} sm={7} md={9} lg={9} xl={9} xxl={9}>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                          <Form.Label>
                            <b>{t("specialist")}:</b> {specialist?.name}{" "}
                            {specialist?.surname}
                          </Form.Label>
                          <Form.Label>
                            <b>{t("address")}:</b> {specialist?.office_address}
                          </Form.Label>
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                          <Form.Label>
                            <b>{t("appointmentType")}:</b>{" "}
                            {t("generalConsultation")} {appointmentType==1?<b> - Cita presencial</b>:<b> - Cita virtual</b>}
                          </Form.Label>
                        </Col>
                        
                        <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                          <Form.Label>
                            <b>{t("date")}: </b>
                            {selectAppointment?.selectedDate?.$D}/
                            {selectAppointment?.selectedDate?.$M + 1}/
                            {selectAppointment?.selectedDate?.$y}
                          </Form.Label>
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                          <Form.Label>
                            <b>{t("time")}: </b>
                            {convertirRangoHoraAMPM(
                              selectAppointment?.selectedHour
                            )}
                          </Form.Label>
                        </Col>

                        <Col
                          xs={12}
                          sm={12}
                          md={12}
                          lg={12}
                          xl={12}
                          xxl={12}
                        ></Col>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                          <Form.Label>
                            <b>{t("payAmount")}: </b>$
                            {new Intl.NumberFormat("es-ES", {
                              minimumFractionDigits: 0,
                            }).format(parseFloat(price))}{" "}
                            {currency}
                          </Form.Label>
                        </Col>
                      </Col>
                    </Row>
                  </fieldset>
                </Col>
                <Col xs={12} sm={12} md={8} lg={8}>
                  <Row>
                    <Col xs sm md lg xl xxl>
                      <Form.Label className="title-secundary">
                        {t("patientInfo")}
                      </Form.Label>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
                      <Form.Label>
                        {t("documentType")}
                        <span className="obligatorio">*</span>
                      </Form.Label>
                      <Form.Select
                        name="payerDocumentType"
                        value={payerInfo.documentType}
                        onChange={(event) =>
                          setPayerInfo((prev) => ({
                            ...prev,
                            documentType: event.target.value,
                          }))
                        }
                      >
                        <option value="17">{t("nationalID")}</option>
                        <option value="18">{t("foreignID")}</option>
                        <option value="19">{t("civilRegistry")}</option>
                        <option value="40">{t("PasaportID")}</option>
                      </Form.Select>
                    </Col>
                    <Col xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
                      <Form.Label>
                        {t("documentNumber")}
                        <span className="obligatorio">*</span>
                      </Form.Label>
                      <Form.Control
                        name="payerDocument"
                        onChange={(event) =>
                          setPayerInfo((prev) => ({
                            ...prev,
                            document: event.target.value,
                          }))
                        }
                        required
                        type="text"
                        value={payerInfo.document}
                      />
                    </Col>
                  </Row>
                  <Row className="mt-1">
                    <Col xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
                      <Form.Label>
                        {t("firstName")} <span className="obligatorio">*</span>
                      </Form.Label>
                      <Form.Control
                        name="payerName"
                        onChange={(event) =>
                          setPayerInfo((prev) => ({
                            ...prev,
                            name: event.target.value,
                          }))
                        }
                        required
                        type="text"
                        value={payerInfo.name}
                      />
                    </Col>
                    <Col xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
                      <Form.Label>
                        {t("lastName")} <span className="obligatorio">*</span>
                      </Form.Label>
                      <Form.Control
                        name="payerLastName"
                        onChange={(event) =>
                          setPayerInfo((prev) => ({
                            ...prev,
                            lastName: event.target.value,
                          }))
                        }
                        required
                        type="text"
                        value={payerInfo.lastName}
                      />
                    </Col>
                    <Col xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
                      <Form.Label>
                        {t("email")}
                        <span className="obligatorio">*</span>
                      </Form.Label>
                      <Form.Control
                        name="payerEmail"
                        onChange={(event) =>
                          setPayerInfo((prev) => ({
                            ...prev,
                            email: event.target.value,
                          }))
                        }
                        required
                        type="text"
                        value={payerInfo.email}
                      />
                    </Col>
                  </Row>

                  <Row className="mt-1">
                    <Col xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
                      <Form.Label>
                        {t("phone")}
                        <span className="obligatorio">*</span>
                      </Form.Label>
                      <Form.Control
                        name="payerPhone"
                        onChange={(event) =>
                          setPayerInfo((prev) => ({
                            ...prev,
                            phone: event.target.value,
                          }))
                        }
                        required
                        type="text"
                        value={payerInfo.phone}
                      />
                    </Col>
                  </Row>
                  {configure ? (
                    <>
                      <Row className="mt-2">
                        <Col xs sm md lg xl xxl>
                          <Form.Label className="title-secundary">
                            {t("selectPaymentMethod")}
                          </Form.Label>
                        </Col>
                      </Row>

                      <Row className="mt-1">
                        <Form.Group>
                          {payPayu && (
                            <Form.Check
                              checked={selectedPayment === "payu"}
                              className="payu"
                              label="PayU Latam"
                              onChange={() => setSelectedPayment("payu")}
                              type="radio"
                              value="payu"
                            />
                          )}
                          {wompi && (
                            <Form.Check
                              style={{ "marginRight": "10px" }}
                              type="radio"
                              label="Pago en Linea Wompi"
                              value="wompi"
                              checked={selectedPayment === "wompi"}
                              onChange={() => setSelectedPayment("wompi")}
                            />
                          )}
                          {(appointmentType!=2 && paySite) && (
                            <Form.Check
                              type="radio"
                              label="Pago en sitio"
                              value="site"
                              checked={selectedPayment === "site"}
                              onChange={() => setSelectedPayment("site")}
                            />
                          )}

                        </Form.Group>
                      </Row>

                      {(selectedPayment === "payu" &&
                        <>

                          <Form.Label className="title-payu">
                            {t("securePayment")}
                          </Form.Label>

                          <Row>
                            <Col xs sm md lg xl xxl>
                              <Form.Label className="title-secundary">
                                {t("buyerInfo")}
                              </Form.Label>
                            </Col>
                          </Row>
                          <Row>
                            <Col
                              xs={12}
                              sm={12}
                              md={12}
                              lg={12}
                              xl={12}
                              xxl={12}
                            >
                              <Form.Check
                                type="checkbox"
                                label={t("buyerSameAsPatient")}
                                id="checkbox-example"
                                className="form-check-inline"
                                checked={isInfoDuplicate}
                                onChange={handleChangeisInfoCheck}
                              />
                            </Col>
                            <Col xs={12} sm={12} md={4} lg={4} xl={4} xxl={4}>
                              <Form.Label>
                                {t("fullName")}
                                <span className="obligatorio">*</span>
                              </Form.Label>
                              <Form.Control
                                name="buyerFullName"
                                onChange={(event) =>
                                  setBuyerInfo((prev) => ({
                                    ...prev,
                                    fullName: event.target.value,
                                  }))
                                }
                                required
                                type="text"
                                value={buyerInfo.fullName}
                                disabled={isInfoDuplicate}
                              />
                            </Col>

                            <Col xs={12} sm={12} md={4} lg={4} xl={4} xxl={4}>
                              <Form.Label>
                                {t("email")}
                                <span className="obligatorio">*</span>
                              </Form.Label>
                              <Form.Control
                                name="buyerEmail"
                                onChange={(event) =>
                                  setBuyerInfo((prev) => ({
                                    ...prev,
                                    email: event.target.value,
                                  }))
                                }
                                required
                                type="text"
                                value={buyerInfo.email}
                                disabled={isInfoDuplicate}
                              />
                            </Col>

                            <Col xs={12} sm={12} md={4} lg={4} xl={4} xxl={4}>
                              <Form.Label>
                                {t("phone")}
                                <span className="obligatorio">*</span>
                              </Form.Label>
                              <Form.Control
                                name="telephone"
                                onChange={(event) =>
                                  setBuyerInfo((prev) => ({
                                    ...prev,
                                    telephone: event.target.value,
                                  }))
                                }
                                required
                                type="text"
                                value={buyerInfo.telephone}
                                disabled={isInfoDuplicate}
                              />
                            </Col>
                          </Row>
                        </>
                      )}
                      <Form.Control
                        type="hidden"
                        required
                        name="referenceCode"
                        defaultValue={referenceCode}
                      />

                      <Row className="mt-2">
                        <Col xs sm md lg xl xxl>
                          {selectedPayment === "site" && (
                            <Row>
                              <Form.Label>
                                {t("reservationUnderReview")}
                              </Form.Label>
                            </Row>
                          )}
                          {selectedPayment !== "" && (
                            <Row>
                              <b>
                                <Form.Label>
                                  {t("clickOn")}
                                  {selectedPayment === "payu"
                                    ? t("pay")
                                    : t("book")}
                                  , {t("privacyPolicy")}{" "}
                                  <a
                                    href={
                                      process.env.REACT_APP_POLITICA_PRIVACIDAD
                                    }
                                    target="_black"
                                  >
                                    {t("termsConditions")}
                                  </a>{" "}
                                  {t("and")}{" "}
                                  <a
                                    target="_black"
                                    href={
                                      process.env
                                        .REACT_APP_TERMINOS_Y_CONDICIONES
                                    }
                                  >
                                    {t("termsAndConditions")}
                                  </a>
                                  .
                                </Form.Label>
                              </b>
                            </Row>
                          )}
                          <Row className="mt-2">
                            {(selectedPayment === "payu") && (
                              <>
                                <Button
                                  name="paid"
                                  type="submit"
                                  value="send"
                                  disabled={isLoading}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="20"
                                    height="20"
                                    fill="currentColor"
                                    className="bi bi-credit-card"
                                    viewBox="0 0 20 18"
                                  >
                                    <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4zm2-1a1 1 0 0 0-1 1v1h14V4a1 1 0 0 0-1-1H2zm13 4H1v5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V7z" />
                                    <path d="M2 10a1 1 0 0 1 1-1h1a1 1 0 0 1 1 1v1a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1v-1z" />
                                  </svg>
                                  {t("pay")}
                                </Button>
                              </>
                            )}

                            {(
                              selectedPayment === "wompi") && (
                                <>
                                  <Button
                                    name="paid"
                                    value="send"
                                    onClick={handlePay}
                                    disabled={isLoading}
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="20"
                                      height="20"
                                      fill="currentColor"
                                      className="bi bi-credit-card"
                                      viewBox="0 0 20 18"
                                    >
                                      <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4zm2-1a1 1 0 0 0-1 1v1h14V4a1 1 0 0 0-1-1H2zm13 4H1v5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V7z" />
                                      <path d="M2 10a1 1 0 0 1 1-1h1a1 1 0 0 1 1 1v1a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1v-1z" />
                                    </svg>
                                    {t("pay")}
                                  </Button>
                                </>
                              )}
                            {selectedPayment === "site" && (
                              <Button
                                name="reserve"
                                type="submit"
                                value="send"
                                disabled={isLoading}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="20"
                                  height="20"
                                  fill="currentColor"
                                  className="bi bi-check-circle"
                                  viewBox="0 0 20 18"
                                >
                                  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                  <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z" />
                                </svg>
                                {t("book")}
                              </Button>
                            )}
                          </Row>
                        </Col>
                      </Row>
                    </>
                  ) : (
                    <>
                      <h6 className="title-secundary form-label mt-3">
                        {t("noPaymentMethod")}
                      </h6>
                    </>
                  )}
                </Col>
              </Row>
            </Form>

            {warning?.show ? (
              <Message
                show={warning?.show}
                message={warning?.message}
                hide={() => setWarning({ show: false })}
              />
            ) : null}
          </Container>
        </ReCAPTCHAWrapper>
      </GoogleReCaptchaProvider>
      <Loader status={isLoading} />
    </>
  );
}

export default Purchasepage;
