import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { renderTimeViewClock } from "@mui/x-date-pickers/timeViewRenderers";
import dayjs, { Dayjs } from "dayjs";
import { useEffect, useState } from "react";
import { Button, Col, Container, Form, Row, Tab, Tabs } from "react-bootstrap";
import {
  Confirmation,
  IMessage,
  Message,
} from "../../../../components/message";
import { IPatient } from "../../../../interfaces/patient/patient.interface";
import { useAuth } from "../../../../middlewares/auth";
import {
  updateAppointmentsCancel,
  useAppointment,
} from "../../../../services/appointment";
import HttpService from "../../../../services/http";
import { createPayment } from "../../../../services/payment";
import useFetchSpecialist from "../../../specialist/hooks/useFetchSpecialist";
import { usePanelPage } from "../../panelPage";
import useFetchAppointmentTypeSpecialist from "../schedule/components/prices/hooks/useFetchAppointmentTypeSpecialist";
import Calendar from "./components/calendar";
import Loader from "../../../../components/Loader";

function createPatient(
  patientInfo: IPatient
): Promise<string | null | undefined> {
  return new Promise((resolve, reject) => {
    HttpService.post("patients/create", {
      document: patientInfo.document,
      document_type_id: patientInfo.document_type_id,
      email: patientInfo.email,
      first_name: patientInfo.first_name,
      last_name: patientInfo.last_name,
      phone: patientInfo.phone,
    }).then((result: any) => {
      resolve(result);
    });
  });
}

interface Result {
  status?: Boolean | false;
  data?: any;
  message?: string; // Hacer que message sea opcional
}

interface IResultCalendar {
  date: Dayjs | null;
  start_datetime: Dayjs | null;
  end_datetime: Dayjs | null;
}

interface ScheduleAppointmentProps {
  appointmentId: any;
  handleAction: any | null;
  hideFotter?: boolean;
}

export default function ScheduleAppointment({
  appointmentId,
  handleAction,
  hideFotter = false,
}: ScheduleAppointmentProps): JSX.Element {
  const { state } = useAuth();

  const [date, setDate] = useState<string>(""); // Cambiado a tipo string
  const [startTime, setStartTime] = useState<Dayjs | null>(null);
  const [endTime, setEndTime] = useState<Dayjs | null>(null);
  const [key, setKey] = useState("1");
  const [duration, setDuration] = useState<string>("manual"); // Cambiado a tipo string
  const [appointmentType, setAppointmentType] = useState<string>("1"); // Cambiado a tipo string
  const [appointmentTime, setAppointmentTime] = useState<string>("");
  const [appointmentPrimary, setAppointmentPrimary] = useState<number>(0);

  const [isSending, setIsSending] = useState<boolean>(false);

  const [statusConfirm, setStatusConfirm] = useState(false);
  const [isMainAppointment, setIsMainAppointment] = useState<boolean>(false);
  const [dateCalendar, setdateCalendar] = useState<IResultCalendar>({
    date: null,
    start_datetime: null,
    end_datetime: null,
  });
  const [reschedule, setReschedule] = useState(false);

  const [disabledPatient, setdisabledPatient] = useState(false);
  const { specialistSelected } = usePanelPage();

  const [idSpecialist, setIdSpecialist] = useState<number | null | undefined>(
    state?.specialist ? state?.id : state?.secretary ? specialistSelected : null
  );
  const [message, setMessage] = useState<IMessage>({
    show: false,
    message: "",
  });

  const { appointment } = useAppointment(appointmentId);

  const { appointmentTypesSpecialist } = useFetchAppointmentTypeSpecialist(
    idSpecialist,
    true
  );

  const { specialist } = useFetchSpecialist(idSpecialist);
  const [ActiveInfo, setActiveInfo] = useState(false);
  const [payerInfo, setPayerInfo] = useState<IPatient>(
    appointment?.patient || {
      id: -1,
      first_name: "",
      last_name: "",
      email: "",
      phone: "",
      gender_id: null,
      document_type_id: 0,
      document: "",
    }
  );

  useEffect(() => {
    if (appointment) {
      setdisabledPatient(true);
      setActiveInfo(true);
      setPayerInfo(appointment?.patient);
    }
  }, [appointment]);

  useEffect(() => {
    setIdSpecialist(
      state?.specialist
        ? state?.id
        : state?.secretary
        ? specialistSelected
        : null
    );
  }, [state?.specialist, state?.secretary, specialistSelected]);

  const createTemporalAppointment = () => {
    let hourStart = startTime?.hour();
    let minutesStart = startTime?.minute();
    let hourEnd = endTime?.hour();
    let minutesEnd = endTime?.minute();
    const type = appointmentTypesSpecialist.find(
      (objeto) => objeto.id === parseInt(appointmentTime)
    );
    const data = {
      paneldata: {
        amount: type?.price || 0,
        referenceCode,
        payment_method_id: 99,
      },
      panel: true,
      patient_id: payerInfo.id,
      specialist_id: `${idSpecialist}`,
      specialist_appointment_type_id: `${appointmentTime}`,
      start_datetime: dayjs(date || new Date())
        .hour(Number(hourStart))
        .minute(Number(minutesStart))
        .second(0)
        .format("YYYY-MM-DD HH:mm:ss"),
      end_datetime: dayjs(date || new Date())
        .hour(Number(hourEnd))
        .minute(Number(minutesEnd))
        .second(0)
        .format("YYYY-MM-DD HH:mm:ss"),
      appointments_status: process.env.REACT_APP_IS_CONFIRM_APPOINTMENT,
      comment: "",
    };
    return HttpService.post("appointments/panel/createAppointment", data);
  };

  const createRescheduleAppointment = () => {
    let hourStart = startTime?.hour();
    let minutesStart = startTime?.minute();
    let hourEnd = endTime?.hour();
    let minutesEnd = endTime?.minute();
    const data = {
      appointment_id: appointmentId,
      start_datetime: dayjs(date || new Date())
        .hour(Number(hourStart))
        .minute(Number(minutesStart))
        .second(0)
        .format("YYYY-MM-DD HH:mm:ss"),
      end_datetime: dayjs(date || new Date())
        .hour(Number(hourEnd))
        .minute(Number(minutesEnd))
        .second(0)
        .format("YYYY-MM-DD HH:mm:ss"),
    };
    return HttpService.post("appointments/panel/rescheduleAppointments", data);
  };

  const handleAppointmentTypeChange = (event: any) => {
    setAppointmentType(event.target.value);
    setDate("");
    setStartTime(null);
    setEndTime(null);
  };

  useEffect(() => {
    if (appointmentPrimary === 1) {
      setAppointmentType("2");
      setIsMainAppointment(true);
    } else {
      setAppointmentType("1");
      setIsMainAppointment(false);
    }
  }, [appointmentPrimary]);

  useEffect(() => {
    if (duration !== "manual" && startTime) {
      const hours = startTime.hour(); // Obtener las horas
      const minutes = startTime.minute(); // Obtener los minutos
      const start = new Date();
      start.setHours(hours);
      start.setMinutes(minutes + parseInt(duration, 10));
      const date = dayjs(start.toString());

      setEndTime(date);
    }
  }, [duration, startTime]);

  const currentDate = new Date().toISOString().split("T")[0];

  const [referenceCode] = useState<string>(
    `${dayjs().format("YYYYMMDDHHmmss")}`
  );

  function handleSearch() {
    setActiveInfo(false);
    if (payerInfo.document_type_id === 0) {
      setMessage({
        show: true,
        message:
          "Debe ingresar Tipo documento y numero documento para buscar un paciente.",
      });
    }

    HttpService.get(
      `patients/getPatientDocument/${payerInfo.document}/${payerInfo.document_type_id}`
    )
      .then((result: any) => {
        const { status, message, data } = result;
        if (status && message === "Ok") {
          setPayerInfo(data);
          setdisabledPatient(true);
          setActiveInfo(true);
        } else if (status && message === "patient_not_found") {
          setMessage({
            show: true,
            message:
              "El paciente no existe, diligencie todos los campos para crearlo.",
          });
          setActiveInfo(true);
          cleanPacient();
          setdisabledPatient(false);
        } else
          setMessage({
            show: true,
            message,
          });
      })
      .catch(() => {
        setdisabledPatient(false);
        setMessage({
          show: true,
          message: "Problemas consultando la información.",
        });
      });
  }

  async function handleCreatePatient() {
    let validate = false;
    if (
      payerInfo?.document_type_id === 0 ||
      payerInfo?.document === "" ||
      payerInfo?.last_name === "" ||
      payerInfo?.first_name === "" ||
      payerInfo?.email === "" ||
      payerInfo?.phone === ""
    ) {
      validate = true;
    }

    if (validate) {
      setMessage({
        show: true,
        message: "Todos los campos (*) son obligatorios",
      });
    } else {
      const result: Result | null = (await createPatient(
        payerInfo
      )) as Result | null;
      if (result) {
        const { status, data, message } = result;
        if (status) {
          setMessage({
            show: true,
            message: "Paciente creado correctamente",
          });
          setPayerInfo(data);
          setdisabledPatient(true);
          setActiveInfo(true);
        } else {
          setMessage({
            show: true,
            message: message,
          });
        }
      } else {
        console.log("No se recibió un resultado válido");
      }
    }
  }

  function handleChangePatient() {
    cleanPacient();
    setdisabledPatient(false);
    setActiveInfo(false);
    setIsMainAppointment(false);
    setAppointmentType("1");
    setAppointmentTime("");
    setAppointmentPrimary(0);
    setPayerInfo((prev) => ({
      ...prev,
      document: "",
      document_type_id: 0,
    }));
  }

  function cleanPacient() {
    setPayerInfo({
      id: -1,
      first_name: "",
      last_name: "",
      email: "",
      phone: "",
      gender_id: null,
      document_type_id: payerInfo.document_type_id,
      document: payerInfo.document,
    });
  }

  const handleRescheduleAppointment = async () => {
    if (isSending) return;

    if (
      date === "" ||
      date === null ||
      startTime === null ||
      endTime === null
    ) {
      setMessage({
        show: true,
        message: "Todos los campos (*) son obligatorios",
      });
      return;
    }

    setIsSending(true);

    try {
      const result = await createRescheduleAppointment();
      if (result.status) {
        setMessage({
          show: true,
          message: "Cita reagendada correctamente",
        });
        if (handleAction) handleAction("save", "Cita reagendada correctamente");
      } else
        setMessage({
          show: true,
          message: result.data || "",
        });
      setIsSending(false);
    } catch (error) {
      console.error("Error al crear la cita:", error);
      setIsSending(false);
    }
  };

  const handleCreateAppointment = async () => {
    if (isSending) return;

    if (
      date === "" ||
      date === null ||
      startTime === null ||
      endTime === null
    ) {
      setMessage({
        show: true,
        message: "Todos los campos (*) son obligatorios",
      });
      return;
    }

    setIsSending(true);

    try {
      const result = await createTemporalAppointment();
      debugger;
      if (result.status) {
        if (result?.status && handleAction)
          handleAction("save", result.message);

        setIsSending(false);
      } else {
        setMessage({
          show: true,
          message: result?.message || "",
        });
        setIsSending(false);
      }
    } catch (error) {
      console.error("Error al crear la cita:", error);
      setIsSending(false);
    }
  };

  useEffect(() => {
    const { date, end_datetime, start_datetime } = dateCalendar;
    setDate(date ? date.toString() : "");
    setStartTime(start_datetime ? start_datetime : null);
    setEndTime(end_datetime ? end_datetime : null);
  }, [dateCalendar]);

  function handleCancel() {
    updateAppointmentsCancel({
      appointment_id: appointmentId,
      appointments_status: 30,
    }).then((result) => {
      if (result.status) {
        setMessage({
          show: true,
          message: "Cita cancelada correctamente",
        });
        if (handleAction) {
          handleAction(
            "cancel",
            "La cita ha sido actualizada a estado cancelada"
          );
        }
      } else {
        setMessage({
          show: true,
          message: result.data || "",
        });
      }
    });
  }

  useEffect(() => {
    if (reschedule) {
      setAppointmentTime(appointment.specialistAppointmentType.id);
      setAppointmentPrimary(
        appointment.specialistAppointmentType.principal_appointment
      );
    }
  }, [reschedule]);

  return (
    <>
      {statusConfirm && (
        <Confirmation
          hide={() => setStatusConfirm(false)}
          show={statusConfirm}
          message="¿Estás seguro de que deseas cancelar la cita?"
          btn={{
            accept: {
              text: "Si",
              event: () => {
                handleCancel();
              },
            },
            cancel: {
              text: "No",
              event: () => {
                setStatusConfirm(false);
              },
            },
          }}
        />
      )}
      <Container fluid>
        <Form>
          <Tabs
            id="controlled-tabs"
            activeKey={key}
            onSelect={(k: any) => setKey(k)}
          >
            <Tab
              eventKey="1"
              title={
                <span>
                  {payerInfo.id !== -1 ? (
                    <i className="bi bi-check-circle-fill m-1" />
                  ) : (
                    <></>
                  )}
                  {!appointment ? "Paciente" : "Resumen"}
                </span>
              }
              disabled={reschedule}
              className="p-2"
            >
              {appointment && (
                <h6 className="title-form-appoiment">Paciente</h6>
              )}

              <Row>
                <Col xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
                  <Form.Label className="title-form-appoiment">
                    Tipo de documento<span className="obligatorio">*</span>
                  </Form.Label>
                  <Form.Select
                    name="payerDocumentType"
                    onChange={(event) =>
                      setPayerInfo((prev: any) => ({
                        ...prev,
                        document_type_id: event.target.value,
                      }))
                    }
                    value={payerInfo?.document_type_id || ""}
                    disabled={disabledPatient}
                  >
                    <option value="0">Seleccionar</option>
                    <option value="17">Cédula de Ciudadania</option>
                    <option value="18">Cédula de Extranjería</option>
                    <option value="18">Cédula de Extranjería</option>
                    <option value="40">Pasaporte</option>
                  </Form.Select>
                </Col>
                <Col xs={11} sm={11} md={5} lg={5} xl={5} xxl={5}>
                  <Form.Label className="title-form-appoiment">
                    Número de documento<span className="obligatorio">*</span>
                  </Form.Label>
                  <Form.Control
                    name="payerDocument"
                    onChange={(event) =>
                      setPayerInfo((prev) => ({
                        ...prev,
                        document: event.target.value,
                      }))
                    }
                    required
                    type="text"
                    disabled={disabledPatient}
                    value={payerInfo.document}
                  />
                </Col>

                <Col xs={2} sm={2} md={1} lg={1} xl={1} xxl={1}>
                  {!disabledPatient ? (
                    <Button
                      className="btnSearchIcon mt-4"
                      title="Consultar Paciente"
                      onClick={handleSearch}
                    >
                      <i className="bi bi-search" />
                    </Button>
                  ) : (
                    <>
                      {!appointment && (
                        <Button
                          className="btnSearchIcon mt-4"
                          title="Cambiar Paciente"
                          onClick={handleChangePatient}
                        >
                          <i className="bi bi-arrow-clockwise" />
                        </Button>
                      )}
                    </>
                  )}
                </Col>
              </Row>
              {ActiveInfo && (
                <>
                  <Row className="mt-1">
                    <Col xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
                      <Form.Label className="title-form-appoiment">
                        Nombre <span className="obligatorio">*</span>
                      </Form.Label>
                      <Form.Control
                        name="payerName"
                        onChange={(event) =>
                          setPayerInfo((prev) => ({
                            ...prev,
                            first_name: event.target.value,
                          }))
                        }
                        required
                        type="text"
                        value={payerInfo.first_name}
                        disabled={disabledPatient}
                      />
                    </Col>
                    <Col xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
                      <Form.Label className="title-form-appoiment">
                        Apellido <span className="obligatorio">*</span>
                      </Form.Label>
                      <Form.Control
                        name="payerLastName"
                        onChange={(event) =>
                          setPayerInfo((prev) => ({
                            ...prev,
                            last_name: event.target.value,
                          }))
                        }
                        required
                        type="text"
                        value={payerInfo.last_name}
                        disabled={disabledPatient}
                      />
                    </Col>
                    <Col xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
                      <Form.Label className="title-form-appoiment">
                        Correo electrónico<span className="obligatorio">*</span>
                      </Form.Label>
                      <Form.Control
                        name="payerEmail"
                        onChange={(event) =>
                          setPayerInfo((prev) => ({
                            ...prev,
                            email: event.target.value,
                          }))
                        }
                        required
                        type="text"
                        value={payerInfo.email}
                        disabled={disabledPatient}
                      />
                    </Col>

                    <Col xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
                      <Form.Label className="title-form-appoiment">
                        Teléfono<span className="obligatorio">*</span>
                      </Form.Label>
                      <Form.Control
                        name="payerPhone"
                        onChange={(event) =>
                          setPayerInfo((prev) => ({
                            ...prev,
                            phone: event.target.value,
                          }))
                        }
                        required
                        type="text"
                        value={payerInfo.phone}
                        disabled={disabledPatient}
                      />
                    </Col>
                  </Row>
                  {appointmentId && (
                    <>
                      <hr style={{ margin: "10px 0px" }} />
                      <h6 className="title-form-appoiment ">Datos cita</h6>
                      <Row>
                        <Col>
                          <Form.Group controlId="date">
                            <Form.Label className="title-form-appoiment">
                              Tipo de cita:
                            </Form.Label>
                            <Form.Label>
                              {appointment?.specialistAppointmentType
                                .appointments_type_id.name || ""}
                            </Form.Label>
                          </Form.Group>
                        </Col>
                        <Col>
                          <Form.Group controlId="date">
                            <Form.Label className="title-form-appoiment">
                              Fecha:
                            </Form.Label>
                            <Form.Label>
                              {dayjs(appointment.start_datetime)?.format(
                                "DD/MM/YYYY"
                              ) || ""}
                            </Form.Label>
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Form.Group controlId="startTime">
                            <Form.Label className="title-form-appoiment">
                              Hora de inicio:
                            </Form.Label>
                            <Form.Label>
                              {appointment.start ??
                                appointment.start_datetimeFormated}
                            </Form.Label>
                          </Form.Group>
                        </Col>
                        <Col>
                          <Form.Group controlId="endTime">
                            <Form.Label className="title-form-appoiment">
                              Hora de finalización:
                            </Form.Label>
                            <Form.Label>
                              {appointment.end ??
                                appointment.end_datetimeFormated}
                            </Form.Label>
                          </Form.Group>
                        </Col>
                      </Row>
                    </>
                  )}
                  <Row className="mt-3 ">
                    <Col xs={8} sm={8} md={8} lg={8} xl={8} xxl={8} />
                    <Col xs={4} sm={4} md={4} lg={4} xl={4} xxl={4}>
                      <Form.Control
                        type="hidden"
                        required
                        name="referenceCode"
                        defaultValue={referenceCode}
                      />
                      {payerInfo.id === -1 ? (
                        <Button
                          style={{ width: "100%" }}
                          onClick={handleCreatePatient}
                        >
                          Crear Paciente
                        </Button>
                      ) : (
                        <>
                          {!appointment ? (
                            <Button
                              style={{ width: "100%" }}
                              onClick={() => setKey("2")}
                            >
                              Siguiente
                            </Button>
                          ) : (
                            <></>
                          )}
                        </>
                      )}
                    </Col>
                  </Row>
                  {appointment && !hideFotter && (
                    <Row>
                      <Col xs={4} sm={4} md={4} lg={4} xl={4} xxl={4} />
                      <Col xs={4} sm={4} md={4} lg={4} xl={4} xxl={4}>
                        <Button
                          style={{ width: "100%" }}
                          onClick={() => setStatusConfirm(true)}
                        >
                          Cancelar Cita
                        </Button>
                      </Col>
                      <Col xs={4} sm={4} md={4} lg={4} xl={4} xxl={4}>
                        <Button
                          style={{ width: "100%" }}
                          onClick={() => {
                            setKey("2");
                            setReschedule(true);
                          }}
                        >
                          Reagendar
                        </Button>
                      </Col>
                    </Row>
                  )}
                </>
              )}
            </Tab>
            <Tab
              eventKey="2"
              title="   Información de la cita"
              className="p-2"
              disabled={appointment && !reschedule}
            >
              <>
                {payerInfo.id !== -1 && (
                  <>
                    <Row>
                      <Col xs sm md lg xl xxl>
                        <Form.Group controlId="formTime">
                          <Form.Label>Tipo Cita:</Form.Label>
                          <Form.Control
                            as="select"
                            value={appointmentTime}
                            disabled={reschedule}
                            onChange={(e) => {
                              const selectElement = e.target as any;
                              const selectedIndex = selectElement.selectedIndex;
                              const selectedOption =
                                selectElement.options[selectedIndex];
                              const appointmentTime = selectedOption.value;
                              const appointmentPrimary = parseInt(
                                selectedOption.getAttribute("data-val") || "0"
                              );
                              setAppointmentTime(appointmentTime);
                              setAppointmentPrimary(appointmentPrimary);
                            }}
                          >
                            <option value="">Selecionar</option>
                            {appointmentTypesSpecialist?.map(
                              ({
                                id,
                                appointments_type_id,
                                principal_appointment,
                              }) => (
                                <option
                                  key={id}
                                  data-val={principal_appointment}
                                  value={id}
                                >
                                  {appointments_type_id?.name}
                                </option>
                              )
                            )}
                          </Form.Control>
                        </Form.Group>
                      </Col>
                    </Row>
                    <>
                      {appointmentTime && appointmentTime !== "" && (
                        <>
                          <Row className="mt-2">
                            <Col>
                              {!isMainAppointment ? (
                                <Form.Group controlId="formDate ">
                                  <Form.Check
                                    type="radio"
                                    label="Cita libre"
                                    name="appointmentType"
                                    id="1"
                                    value="1"
                                    checked={appointmentType === "1"}
                                    onChange={handleAppointmentTypeChange}
                                    inline
                                  />
                                  <Form.Check
                                    type="radio"
                                    label="Cita con horario"
                                    name="appointmentType"
                                    id="2"
                                    value="2"
                                    checked={appointmentType === "2"}
                                    onChange={handleAppointmentTypeChange}
                                    inline
                                  />
                                </Form.Group>
                              ) : null}
                            </Col>
                          </Row>

                          {appointmentType === "2" ? (
                            <Calendar
                              handleReserve={
                                reschedule
                                  ? handleRescheduleAppointment
                                  : handleCreateAppointment
                              }
                              setSelect={setdateCalendar}
                              id={specialist?.id + ""}
                              specialist={specialist}
                              reschedule={reschedule}
                            />
                          ) : (
                            <>
                              <Row>
                                <Col>
                                  <Form.Group controlId="formDate">
                                    <Form.Label>
                                      Fecha
                                      <span className="obligatorio">*</span>
                                    </Form.Label>
                                    <Form.Control
                                      type="date"
                                      value={date}
                                      min={currentDate}
                                      onChange={(e) => setDate(e.target.value)}
                                    />
                                  </Form.Group>
                                </Col>
                                <Col>
                                  <Form.Group controlId="formDuration">
                                    <Form.Label>Duración:</Form.Label>
                                    <Form.Control
                                      as="select"
                                      value={duration}
                                      onChange={(e) =>
                                        setDuration(e.target.value)
                                      }
                                    >
                                      <option value="manual">Manual</option>
                                      <option value="30">30 min</option>
                                      <option value="60">60 min</option>
                                      <option value="90">90 min</option>
                                    </Form.Control>
                                  </Form.Group>
                                </Col>
                              </Row>
                              <Row>
                                <Col>
                                  <Form.Group controlId="formStartTime">
                                    <LocalizationProvider
                                      dateAdapter={AdapterDayjs}
                                    >
                                      <Form.Label>
                                        Hora inicio
                                        <span className="obligatorio">*</span>
                                      </Form.Label>
                                      <br></br>

                                      <TimePicker
                                        onChange={(newValue: any) =>
                                          setStartTime(newValue)
                                        }
                                        value={startTime}
                                        className="customTimePicker"
                                        viewRenderers={{
                                          hours: renderTimeViewClock,
                                          minutes: renderTimeViewClock,
                                          seconds: renderTimeViewClock,
                                        }}
                                        sx={{ height: "30px" }}
                                      />
                                    </LocalizationProvider>
                                  </Form.Group>
                                </Col>
                                <Col>
                                  <Form.Group controlId="formEndTime">
                                    <Form.Label>
                                      Hora fin
                                      <span className="obligatorio">*</span>
                                    </Form.Label>
                                    <br></br>
                                    <LocalizationProvider
                                      dateAdapter={AdapterDayjs}
                                    >
                                      <TimePicker
                                        disabled={duration !== "manual"}
                                        onChange={(newValue: any) =>
                                          setEndTime(newValue)
                                        }
                                        value={endTime}
                                        minTime={startTime}
                                        className="customTimePicker"
                                        viewRenderers={{
                                          hours: renderTimeViewClock,
                                          minutes: renderTimeViewClock,
                                          seconds: renderTimeViewClock,
                                        }}
                                      />
                                    </LocalizationProvider>
                                  </Form.Group>
                                </Col>
                              </Row>
                              <Row className="mt-3">
                                <Col
                                  xs={8}
                                  sm={8}
                                  md={8}
                                  lg={8}
                                  xl={8}
                                  xxl={8}
                                />
                                <Col xs={4} sm={4} md={4} lg={4} xl={4} xxl={4}>
                                  {reschedule ? (
                                    <Button
                                      disabled={isSending}
                                      style={{ width: "100%" }}
                                      onClick={handleRescheduleAppointment}
                                    >
                                      Reagendar cita
                                    </Button>
                                  ) : (
                                    <Button
                                      disabled={isSending}
                                      style={{ width: "100%" }}
                                      onClick={handleCreateAppointment}
                                    >
                                      Reservar cita
                                    </Button>
                                  )}
                                </Col>
                              </Row>
                            </>
                          )}
                        </>
                      )}
                    </>
                  </>
                )}
              </>
            </Tab>
          </Tabs>
        </Form>
        {message?.show ? (
          <Message
            show={message?.show}
            message={message?.message}
            hide={() => setMessage((prev: any) => ({ ...prev, show: false }))}
          />
        ) : null}
        <Loader status={isSending}></Loader>
      </Container>
    </>
  );
}
